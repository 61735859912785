import Web3 from "web3";

export const clearLocalStorage = () => {
  window.localStorage.removeItem('address')
  window.localStorage.removeItem('signatureResult')
  window.localStorage.removeItem('signatureMessage')
  window.localStorage.removeItem('wallet_type')
}

let isFetchSign = false;

const personalSign = (provider: any, msg: string, fromAddress: string, pwd: string) => {
  msg = 'Welcome to Cobak!\n\n' +
    'Click "Sign" to continue. \nThis signature will cost 0 gas.\n\n' +
    'Wallet address:\n' +
    fromAddress + '\n\n' +
    'Nonce:\n' + msg;

  const web3 = new Web3(provider);
  if (isFetchSign) {
    return {};
  }
  isFetchSign = true;
  return new Promise((resolve, reject) => {
    web3.eth.personal.sign(msg, fromAddress, pwd).then(res => {
      localStorage.setItem('address', fromAddress)
      localStorage.setItem('signatureResult', res)
      localStorage.setItem('signatureMessage', encodeURIComponent(msg))
      isFetchSign = false;
      resolve({ address: fromAddress, signature: res, signatureMessage: encodeURIComponent(msg) })
    }).catch(err => {
      isFetchSign = false;
      reject(err)
    })
  })
}

export const handleIsSignExpired = () => {
  let nowTime = Date.now();
  let address, signature, signatureMessage;

  address = localStorage.getItem('address')
  signature = localStorage.getItem('signatureResult')
  signatureMessage = localStorage.getItem('signatureMessage')

  if (!signature || !signatureMessage) {
    return
  }

  const decodeSignTime: any = decodeURIComponent(signatureMessage);
  const signTime: any = decodeSignTime.split('Nonce:\n')[1];

  if (nowTime - signTime > 604800000) {
    window.localStorage.removeItem('signatureResult')
    window.localStorage.removeItem('signatureMessage')
    window.location.reload()
  }

  return { address, signature, signatureMessage }
}

export const getSign = async (library: any, account: any, force: any) => {
  let signature, signatureMessage, address, storedSignature: any;
  if (!force) {
    storedSignature = handleIsSignExpired()
  }

  if (!account) {
    return {}
  }

  if (!storedSignature || (account !== storedSignature.address)) {
    signatureMessage = Date.now()

    if (library?.provider) {
      storedSignature = await personalSign(library.provider, signatureMessage.toString(), account, '')
      signature = storedSignature.signature
      signatureMessage = storedSignature.signatureMessage
      address = storedSignature.address
    }
  } else {
    signature = storedSignature.signature
    signatureMessage = storedSignature.signatureMessage
    address = storedSignature.address
  }

  return { signature, signatureMessage, address: account }
}