import { useCallback, useEffect, useState } from 'react';
import { get, request } from 'utils/request';
import { API_URL } from 'constants/index';
import { getSign, handleIsSignExpired } from 'utils/txSign';
import useActiveWeb3React from "hooks/useActiveWeb3React";

// export function useProjectList(status: string, search?: any): any{
//     const [list, setList] = useState<any>(new Array(2).fill({}));
//     const fetchData = () => {
//         let params: any = {
//             status,
//             search
//         };
//         status === 'all' && delete params.status
//         !search && delete params.search
//         let query = Object.keys(params)
//         .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
//         .join('&');
//         get(`${API_URL}/projects?${query}`).then((res: any) => {
//             if(res && res.data){
//                 setList(res.data.list)
//             }
//         })
//     }
//     useEffect(() => {
//         fetchData()
//     },[status, search])
//     return list;
// }

export function useProjectList(status: string, pageNo: number, pageSize: number, search?: any, refresh?: number): any {
    const [list, setList] = useState<any>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const { account, library } = useActiveWeb3React();
    const fetchData = useCallback(async () => {
        const sign = await getSign(library, account, false)
        let params: any = {
            status,
            pageNo,
            pageSize,
            search
        };
        if (status === 'all') delete params.status
        if (!search) delete params.search
        let query = Object.keys(params)
            .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        get(`${API_URL}/projects?${query}`, sign).then((res: any) => {
            if (res && res.data) {
                if (pageNo === 1) {
                    setList(res.data.list)
                } else {
                    setList((oldList: any) => [...oldList, ...res.data.list])
                }
                setTotalCount(res.data.totalCount)
            }
        })
    }, [library, account, status, pageNo, pageSize, search])
    useEffect(() => {
        fetchData()
    }, [status, pageNo, pageSize, search, refresh])
    return { list, totalCount };
}

export function useProjectDetail(projectId: string, refresh: number): any {
    const [data, setData] = useState<any>({})
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async () => {
        const header: any = await getSign(library, account, false)
        get(`${API_URL}/projects/${projectId}`, header).then((res: any) => {
            if (res && res.data) {
                setData(res.data);
            }
        })
    }, [projectId, account, library])
    useEffect(() => {
        if (projectId) {
            fetchData()
        }
    }, [projectId, refresh])
    return data;
}

export function useQualification(projectId: string, status?: string): any {
    const [data, setData] = useState<any>({})
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async () => {
        const header: any = await getSign(library, account, false)
        get(`${API_URL}/projects/${projectId}/qualification`, header).then((res: any) => {
            if (res && res.data) {
                setData(res.data)
            }
        })
    }, [projectId, account, library, status])
    useEffect(() => {
        if (projectId) {
            fetchData()
        }
    }, [projectId, status])
    return data;
}