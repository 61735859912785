import { useCallback, useState } from 'react';
import { Layout, Form, Input, Button, Row, Col, DatePicker, message } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import ReactMde from "react-mde";
import { useTranslation } from 'react-i18next'
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router";
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { postCreateProposal } from './hooks';
import "react-mde/lib/styles/css/react-mde-all.css";
import './index.less'

const { Content } = Layout;
const { RangePicker } = DatePicker;

const Create = () => {
    const [form] = Form.useForm();
    const [description, setDescription] = useState("")
    const [selectedTab, setSelectedTab] = useState<any>("write");
    const { account, library } = useActiveWeb3React()
    const history = useHistory();
    const { t } = useTranslation()

    const onFinish = useCallback(async(values: any) => {
        console.log('Success:', values);
        const rangeTimeValue = values['range-time-picker'];
        const params = {
            title: values.title,
            options: values.options,
            description,
            startTime: rangeTimeValue[0].valueOf(),
            endTime: rangeTimeValue[1].valueOf()
        }
        const res = await postCreateProposal(params, account, library);
        if(res.code === 'success'){
            message.success('Create Proposal Success');
            history.push('/proposals')
        }
    }, [account, library, description]);
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);

    };
      
    const rangeConfig = {
        rules: [{ type: 'array' as const, required: true, message: 'Please select time!' }],
    };
    const formItemLayout1 = {
        labelCol: {
          xs: { span: 24 },
        },
        wrapperCol: {
          xs: { span: 24 },
        },
      };
      
    const formItemLayout2 = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };
      
    
    return <div className="createProposal">
        <Content>
            <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                hideRequiredMark
                initialValues={{options: ['Approve', 'Disapprove']}}
            >
                <Form.Item
                    {...formItemLayout1}
                    label={t('page_governance_create_label_title')}
                    name="title"
                    rules={[{ required: true, message: 'Please input Title!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('page_governance_create_label_content')}
                    {...formItemLayout1}
                >
                    <ReactMde
                        value={description}
                        onChange={setDescription}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown: any) =>
                            Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                        }
                        minEditorHeight={280}
                        childProps={{
                            writeButton: {
                                tabIndex: -1
                            }
                        }}
                    />
                </Form.Item>
                <Form.List
                    name="options"
                    {...formItemLayout1}
                >
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Row className='option'>
                                <Col flex="auto">
                                    <Form.Item
                                        {...field}
                                        key={field.key}
                                        validateTrigger={['onChange', 'onBlur']}
                                        style={{width: 'calc(100 - 50px)'}}
                                        rules={[
                                            {
                                            required: true,
                                            whitespace: true,
                                            message: "Please input passenger's name or delete this field.",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input placeholder="Option"/>
                                    </Form.Item>
                                </Col>
                                <Col flex="100px">{fields.length > 1 ? (
                                    <MinusCircleOutlined
                                        style={{fontSize: '24px'}}
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}</Col>
                            </Row>
                            ))}
                            <Form.Item>
                            <Button
                                type="default"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                                block
                            >
                                Add
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                    )}
                </Form.List>
                <Form.Item {...formItemLayout2} name="range-time-picker" label={t('page_governance_create_vote_options')} {...rangeConfig}>
                    <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>
                {/* <div className='info'>
                    <div>
                        <div>Your balance</div>
                        <div>1 ETH</div>
                    </div>
                    <div>
                        <div>Create a proposal need hold</div>
                        <div>500CBK</div>
                    </div>
                </div> */}
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>{t('page_governance_create_button')}</Button>
                </Form.Item>
            </Form>
        </Content>
    </div>
}

export default Create;