import MULTICALL_ABI from "./abi.json";
import { ChainId } from "constants/index";

const MULTICALL_NETWORKS: any = {
  [ChainId.MAINNET]: "0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441",
  [ChainId.ROPSTEN]: "0x53C43764255c17BD724F74c4eF150724AC50a3ed",
  [ChainId.KOVAN]: "0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A",
  [ChainId.RINKEBY]: "0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821",
  [ChainId.GÖRLI]: "0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e",
  80001: "0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc",
  137: "0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507",
  [ChainId.SEPOLIA]: "0xcA11bde05977b3631167028862bE2a173976CA11",
  // 56: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  // 97: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A'
};

export { MULTICALL_ABI, MULTICALL_NETWORKS };
