import { Modal, Table } from 'antd';
import { shortenAddress } from 'utils';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next'
import { formatUnits } from "@ethersproject/units";
import { useState } from 'react';

const VotesModal = ({ visible, handleCancel, list, totalCount, handlePageChange }: any) => {
  const { t } = useTranslation()

  const columns = [
    {
      dataIndex: 'voter',
      key: 'voter',
      render: (v: any) => shortenAddress(v)
    },
    {
      dataIndex: 'optionContent',
      key: 'optionContent',
    },
    {
      dataIndex: 'count',
      key: 'count',
      render: (v: any) => numeral(formatUnits(v, 18)).format('0,0')
    },
  ];

  return <Modal className='votesModal' footer={null} title={`${t('modal_title_votes')} (${totalCount})`} visible={visible} onCancel={handleCancel}>
    <Table
      dataSource={list}
      columns={columns}
      pagination={
        {
          position: ['bottomCenter'],
          size: 'small',
          total: totalCount,
          pageSize: 10
        }
      }
      onChange={handlePageChange}
    />
  </Modal>
}

export default VotesModal