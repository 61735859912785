import { useCallback, useEffect, useState } from "react";
import { get } from "utils/request";
import { API_URL } from "constants/index";

export function useTiers(): any {
  const [list, setList] = useState<any>([]);
  const fetchData = useCallback(async () => {
    get(`${API_URL}/stakes/tiers`).then((res: any) => {
      if (res && res.data) {
        setList(res.data.tiers);
      }
    });
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  return list;
}
