import { useCallback, useEffect, useState } from 'react';
import Web3 from "web3";
import { get, request } from 'utils/request';
import { API_URL } from 'constants/index';
import { getSign, handleIsSignExpired } from 'utils/txSign';
import useActiveWeb3React from "hooks/useActiveWeb3React";

export function useProposalsList(status: string, pageNo: number, pageSize: number, refresh?: number): any {
    const [list, setList] = useState<any>(new Array(2).fill({}));
    const [totalCount, setTotalCount] = useState<number>(0);
    const fetchData = () => {
        let params: any = status !== 'all' ? {
            status,
            pageNo,
            pageSize,
        } : {
            pageNo,
            pageSize
        }
        let query = Object.keys(params)
            .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        get(`${API_URL}/votes/proposals?${query}`).then((res: any) => {
            if (res && res.data) {
                setList(res.data.list)
                setTotalCount(res.data.totalCount)
            }
        })
    }
    useEffect(() => {
        fetchData()
    }, [status, pageNo, pageSize, refresh])
    return { list, totalCount };
}

export function useAllVoteRecordsList(proposalId: string, pageNo: number, pageSize: number): any {
    const [list, setList] = useState<any>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const fetchData = () => {
        let params: any = {
            pageNo,
            pageSize,
        }
        let query = Object.keys(params)
            .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        get(`${API_URL}/votes/proposals/${proposalId}/voteRecords?${query}`).then((res: any) => {
            if (res && res.data) {
                setList(res.data.list)
                setTotalCount(res.data.totalCount)
            }
        })
    }
    useEffect(() => {
        fetchData()
    }, [pageNo])
    return { list, totalCount, pageNo };
}

export const postCreateProposal = async (data: any, account: any, library: any) => {
    const header: any = await getSign(library, account, false)
    if (!header) {
        return null
    }
    try {
        const url = `${API_URL}/votes/proposals`
        const res = await request(url, {
            headers: {
                ...header,
                'content-type': 'application/json',
                'mode': 'no-cors',
            },
            body: JSON.stringify(data),
            method: 'POST'
        });
        return res
    } catch (e) {
        return null
    }
}

export function useProposalDetail(proposalId: string, refresh?: number): any {
    const [data, setData] = useState<any>({})
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async () => {
        const header: any = await getSign(library, account, false)
        get(`${API_URL}/votes/proposals/${proposalId}`, header).then((res: any) => {
            if (res && res.data) {
                setData(res.data)
            }
        })
    }, [proposalId])
    useEffect(() => {
        if (proposalId) {
            fetchData()
        }
    }, [proposalId, refresh])
    return data;
}

export const postVoteProposal = async (proposalId: string, optionId: string, count: string, account: any, library: any) => {
    const header: any = await getSign(library, account, false)
    if (!header) {
        return null
    }
    try {
        const url = `${API_URL}/votes/proposals/${proposalId}/options/${optionId}/vote?count=${count}`
        const res = await request(url, {
            headers: {
                ...header,
            },
            method: 'POST'
        });
        return res
    } catch (e) {
        return null
    }
}


export function useRole(): any {
    const [role, setRole] = useState<any>('')
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async () => {
        const header: any = await getSign(library, account, false)
        get(`${API_URL}/role`, header).then((res: any) => {
            if (res && res.data) {
                setRole(res.data.role.toLowerCase())
            }
        })
    }, [])
    useEffect(() => {
        if (account) {
            fetchData()
        }
    }, [account])
    return role;
}