import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { formatEther } from '@ethersproject/units';
import { useHistory } from 'react-router';
import React, { useMemo, useEffect, useState } from 'react';
import { Activity } from 'react-feather';
import {
  networkListener,
  setupEthMainnet,
  setupEthTestnet,
} from 'utils/bscUtil';
import { Button } from 'antd';
import { UserAddOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg';
import PortisIcon from '../../assets/img/portisIcon.png';
import WalletConnectIcon from '../../assets/img/walletConnectIcon.svg';
import { injected, portis, walletconnect } from 'constants/index';
import { NetworkContextName } from '../../constants';
import { useWalletModalToggle } from '../../state/application/hooks';
import {
  isTransactionRecent,
  useAllTransactions,
} from '../../state/transactions/hooks';
import { TransactionDetails } from '../../state/transactions/reducer';
import { shortenAddress } from '../../utils';
import Identicon from '../Identicon';
import WalletModal from '../WalletModal';
import { ReactComponent as WalletIcon } from 'assets/img/wallet.svg';
import './index.less';

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`;

const Web3StatusGeneric = styled.button`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0 0.5rem;
  width: 199px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
  border: 1px solid #ffffff;
`;

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  color: #000;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
  `};
`;

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background: transparent;
  color: ${({ theme }) => theme.white};
  font-weight: 500;

  ${({ faded }) =>
    faded &&
    css`
      background: transparent;
      color: ${({ theme }) => theme.white};
    `}
  .anticon svg {
    display: none;
  }
  @media (max-width: 550px) {
    width: auto;
    .anticon svg {
      display: inline-block;
    }
    ${Text} {
      display: none;
    }
  }
`;

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background: transparent;
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: 500;
  @media (max-width: 767px) {
    width: auto;
  }
`;

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`;
const AccountWraper = styled.span`
  margin-left: 6px;
  @media (max-width: 767px) {
    display: none;
  }
`;
// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime;
}

const SOCK = (
  <span
    role="img"
    aria-label="has socks emoji"
    style={{ marginTop: -4, marginBottom: -4 }}
  >
    🧦
  </span>
);

// eslint-disable-next-line react/prop-types
function StatusIcon({ connector }: { connector: AbstractConnector }) {
  if (connector === injected) {
    return <Identicon />;
  } else if (connector === walletconnect) {
    return (
      <IconWrapper size={16}>
        <img src={WalletConnectIcon} alt={''} />
      </IconWrapper>
    );
  } else if (connector === portis) {
    return (
      <IconWrapper size={16}>
        <img src={PortisIcon} alt={''} />
      </IconWrapper>
    );
  }
  return null;
}

function Web3StatusInner({ isSmallScreen }: any) {
  const { t } = useTranslation();
  const { chainId, account, library, error } = useWeb3React();

  const allTransactions = useAllTransactions();
  const history = useHistory();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter((tx) => !tx.receipt)
    .map((tx) => tx.hash);
  const [ethBalance, setEthBalance] = useState<any>();

  useEffect(() => {
    if (library && account) {
      let stale = false;

      library
        .getBalance(account)
        .then((balance: any) => {
          if (!stale) {
            setEthBalance(balance);
          }
        })
        .catch(() => {
          if (!stale) {
            setEthBalance(null);
          }
        });

      return () => {
        stale = true;
        setEthBalance(undefined);
      };
    }
  }, [library, account, chainId, pending.length]);

  useEffect(() => {
    networkListener();
  }, []);

  const hasPendingTransactions = !!pending.length;
  const toggleWalletModal = useWalletModalToggle();

  if (error instanceof UnsupportedChainIdError) {
    if (chainId !== parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')) {
      process.env.REACT_APP_CHAIN_ID === '4'
        ? setupEthTestnet()
        : setupEthMainnet();
    }
  }

  if (account) {
    return (
      <div
        className="account-content"
        onClick={() => {
          history.push('/wallet');
        }}
      >
        {hasPendingTransactions ? (
          <Button size="large" type="default">
            {pending?.length} {t('pending')}
            <LoadingOutlined style={{ color: '#fff' }} />
          </Button>
        ) : (
          <>
            <div className="ethBalance">
              {ethBalance === undefined
                ? '...'
                : ethBalance === null
                ? 'Error'
                : `${parseFloat(formatEther(ethBalance)).toPrecision(4)} ETH`}
            </div>
            <Button size="large" type="default">
              <WalletIcon className="wallet" />
              {!isSmallScreen && (
                <AccountWraper>{shortenAddress(account)}</AccountWraper>
              )}
            </Button>
          </>
        )}
      </div>
    );
  } else if (error) {
    return (
      <div className="account-content">
        <Button size="large" type="default" onClick={toggleWalletModal}>
          <NetworkIcon />
          <AccountWraper>
            {error instanceof UnsupportedChainIdError
              ? 'Wrong Network'
              : 'Error'}
          </AccountWraper>
        </Button>
      </div>
    );
  } else {
    return (
      <div className="account-content">
        <Button size="large" type="default" onClick={toggleWalletModal}>
          <WalletIcon className="wallet" />
          <AccountWraper>{t('conect_wallet')}</AccountWraper>
        </Button>
      </div>
    );
  }
}

export default function Web3Status({ isSmallScreen }: any) {
  const { active, account } = useWeb3React();
  const contextNetwork = useWeb3React(NetworkContextName);

  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter((tx) => !tx.receipt)
    .map((tx) => tx.hash);
  const confirmed = sortedRecentTransactions
    .filter((tx) => tx.receipt)
    .map((tx) => tx.hash);

  if (!contextNetwork.active && !active) {
    return null;
  }

  return (
    <div className="web3-status">
      <Web3StatusInner isSmallScreen={isSmallScreen} />
      <WalletModal
        pendingTransactions={pending}
        confirmedTransactions={confirmed}
      />
    </div>
  );
}
