import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import { MenuOutlined } from "@ant-design/icons";
import { Layout, Select } from "antd";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import Web3Status from "../Web3Status";
import { setupEthMainnet, setupEthTestnet } from "utils/bscUtil";
import { useWalletModalToggle } from "state/application/hooks";
import Logo from "assets/img/Logo.svg";
import "./index.less";

moment.locale("en");
const { Option } = Select;
const { Header: HeaderElement } = Layout;

const Header = () => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  let location = useLocation();
  const { account, chainId } = useActiveWeb3React();
  const { t } = useTranslation();
  const toggleWalletModal = useWalletModalToggle();

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };
  const history = useHistory();

  useEffect(() => {
    if (chainId) {
      document.addEventListener("visibilitychange", function () {
        if (document.visibilityState === "visible") {
          if (chainId !== parseInt(process.env.REACT_APP_CHAIN_ID ?? "1")) {
            process.env.REACT_APP_CHAIN_ID === "4"
              ? setupEthTestnet()
              : setupEthMainnet();
          }
        }
      });
      if (document.visibilityState === "visible") {
        if (chainId !== parseInt(process.env.REACT_APP_CHAIN_ID ?? "1")) {
          process.env.REACT_APP_CHAIN_ID === "4"
            ? setupEthTestnet()
            : setupEthMainnet();
        }
      }
    }
  }, [chainId]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    if (location.pathname && isNavVisible) {
      setNavVisibility(!isNavVisible);
    }
  }, [location]);

  const handleMediaQueryChange = (mediaQuery: any) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const changeLocale = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("cobak_lang", selectedLanguage);
  };

  const agent = window.navigator.userAgent.toLocaleLowerCase();

  return (
    <HeaderElement
      style={{
        background: location.pathname.startsWith("/project/")
          ? "#00000066"
          : "linear-gradient(90deg, #4880EE 0%, #155BE3 100%)",
      }}
    >
      <div className="header">
        <button onClick={toggleNav} className="Burger">
          <MenuOutlined style={{ color: "#fff" }} />
        </button>
        <Link to="/">
          <img
            src={Logo}
            className="Logo"
            alt="logo"
            onClick={() => history.push("/")}
          />
        </Link>
        {!isSmallScreen && (
          <CSSTransition
            in={!isSmallScreen || isNavVisible}
            timeout={350}
            classNames="NavAnimation"
            unmountOnExit
          >
            <>
              <nav className="Nav left">
                <Link
                  to="/projects"
                  className={location.pathname === "/projects" ? "active" : ""}
                >
                  {t("header_menu_launchpad")}
                </Link>
                <Link
                  to="/staking"
                  className={location.pathname === "/staking" ? "active" : ""}
                >
                  {t("header_menu_staking")}
                </Link>
                <Link
                  to="/proposals"
                  className={location.pathname === "/proposals" ? "active" : ""}
                >
                  {t("header_menu_governance")}
                </Link>
              </nav>
              <nav className="Nav right">
                <Select
                  defaultValue={localStorage.getItem("cobak_lang") || "en"}
                  onChange={changeLocale}
                >
                  <Option value="en">English</Option>
                  <Option value="ko">한국어</Option>
                </Select>
                <Web3Status isSmallScreen={isSmallScreen} />
              </nav>
            </>
          </CSSTransition>
        )}
        {isSmallScreen && (
          <CSSTransition
            in={!isSmallScreen || isNavVisible}
            timeout={350}
            classNames="NavAnimation"
            unmountOnExit
          >
            <nav className="Nav left">
              <Link
                to="/proposals"
                className={location.pathname === "/proposals" ? "active" : ""}
              >
                Goverance{" "}
              </Link>
              <Link
                to="/staking"
                className={location.pathname === "/staking" ? "active" : ""}
              >
                Staking{" "}
              </Link>
              <Link
                to="/projects"
                className={location.pathname === "/projects" ? "active" : ""}
              >
                Projects{" "}
              </Link>
              <Select
                defaultValue={localStorage.getItem("cobak_lang") || "en"}
                onChange={changeLocale}
              >
                <Option value="en">English</Option>
                <Option value="ko">한국어</Option>
              </Select>
            </nav>
          </CSSTransition>
        )}
        <button
          onClick={() => {
            return account ? history.push("/wallet") : toggleWalletModal();
          }}
          className="wallet-icon"
        >
          <Web3Status isSmallScreen={isSmallScreen} />
        </button>
      </div>
      {(!(agent.indexOf("chrome") > -1) || agent.indexOf("mobile") > -1) && (
        <div className="pc_chorme_message">{t("pc_chrome_message")}</div>
      )}
    </HeaderElement>
  );
};
export default Header;
