import React, { useContext } from "react";
// import { AlertCircle, CheckCircle } from "react-feather";
import styled, { ThemeContext } from "styled-components";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { ExternalLink } from "../../theme/components";
import { getEtherscanLink } from "../../utils";
import { AutoColumn } from "../Column";
import { AutoRow } from "../Row";

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
  align-items: start;
`;

const Circle = styled.div<{ color: "green" | "yellow" }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(theme) =>
    theme.color === "green" ? "#31B138" : "#FDCB37"};
`;

const CustomAutoColumn = styled(AutoColumn)`
  color: #a8b0c1;
  h2 {
    color: #eff2f5;
  }

  a {
    color: #a8b0c1;
  }
`;

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string;
  success?: boolean;
  summary?: string;
}) {
  const { chainId } = useActiveWeb3React();

  // const theme = useContext(ThemeContext);

  return (
    <RowNoFlex>
      <div style={{ padding: "14px 8px 0 0" }}>
        {success ? <Circle color="green" /> : <Circle color="yellow" />}
      </div>
      <CustomAutoColumn gap="8px">
        <h2>
          {summary ?? "Hash: " + hash.slice(0, 8) + "..." + hash.slice(58, 65)}
        </h2>
        {chainId && (
          <ExternalLink href={getEtherscanLink(chainId, hash, "transaction")}>
            View on Etherscan
          </ExternalLink>
        )}
      </CustomAutoColumn>
    </RowNoFlex>
  );
}
