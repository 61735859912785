import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import useCopyClipboard from 'hooks/useCopyClipboard'

import { CheckCircle, Copy } from 'react-feather'

const TransactionStatusText = styled.span`
  ${({ theme }) => theme.flexRowNoWrap};
  margin-left: 10px;
  align-items: center;
`

export default function CopyHelper(props: { toCopy: string; children?: React.ReactNode }) {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <Button type="default" style={{ border: 'none' }} onClick={() => setCopied(props.toCopy)}>
      {isCopied ? (
        <TransactionStatusText>
          <CheckCircle size={'14'} />
          {/* <TransactionStatusText>Copied</TransactionStatusText> */}
        </TransactionStatusText>
      ) : (
        <TransactionStatusText>
          <Copy size={'14'} />
        </TransactionStatusText>
      )}
      {props.children}
    </Button>
  )
}
