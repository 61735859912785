import React, { useState, useEffect } from "react";
import {
  Layout,
  Select,
  Tag,
  Button,
  Statistic,
  Pagination,
  Skeleton,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useWalletModalToggle } from "state/application/hooks";
import { useProposalsList, useRole } from "./hooks";
import "./index.less";
const { Content } = Layout;
const Option = Select;
const { Countdown } = Statistic;

const ProposalItem = ({ proposal, refresh, setRefresh }: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const onFinish = () => {
    setRefresh(refresh + 1);
  };

  return Object.keys(proposal).length > 0 ? (
    <div
      className="item"
      key={proposal.id}
      onClick={() => {
        history.push(`/proposal/${proposal.id}`);
      }}
    >
      <div>
        <h1>
          <Tag>{proposal.id}</Tag>
          {proposal?.title}
        </h1>
        <p>{proposal?.description}</p>
        <div className="status">
          <div className="end_time">
            {proposal?.status === "soon" ? (
              <Countdown
                onFinish={onFinish}
                prefix={`${t("page_staking_label_start_at")}   `}
                value={proposal.startTime}
                format="D[d] H[h] m[m] s[s]"
              />
            ) : (
              `${t("page_staking_label_ended_at")} ${moment(
                proposal.endTime
              ).format("YYYY-MM-DD HH:mm:ss")}`
            )}
          </div>
          <div>
            <Tag className={proposal.status}>
              {t(`page_governance_status_${proposal.status}`)}
            </Tag>
          </div>
        </div>
      </div>
      <div>
        <Button
          type="default"
          icon={<RightOutlined style={{ fontSize: "20px" }} />}
        />
      </div>
    </div>
  ) : (
    <Skeleton paragraph={{ rows: 2 }} active />
  );
};

export default function Proposals() {
  const { account } = useActiveWeb3React();
  const { t } = useTranslation();
  const [pageNo, setPageNo] = useState(1);
  const [status, setStatus] = useState("all");
  const [refresh, setRefresh] = useState<number>(1);
  const { list: proposals, totalCount } = useProposalsList(
    status,
    pageNo,
    5,
    refresh
  );
  const { totalCount: passedCount } = useProposalsList("passed", pageNo, 1);
  const { totalCount: failedCount } = useProposalsList("failed", pageNo, 1);
  const history = useHistory();
  const toggleWalletModal = useWalletModalToggle();
  const role = useRole();
  const handlePageChange = (page: number) => {
    setPageNo(page);
  };

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  return (
    <div className="proposals">
      <Content>
        <div className="head">
          <div>
            <h1>{t("page_governance_title")}</h1>
            <p>{t("page_governance_subtitle")}</p>
          </div>
          <div>
            {account && role.includes("admin") && (
              <Button
                type="primary"
                onClick={() => {
                  history.push("/proposal/create");
                }}
              >
                {t("page_governance_button")}
              </Button>
            )}
            {!account && (
              <Button type="primary" onClick={toggleWalletModal} block>
                {t("conect_wallet")}
              </Button>
            )}
          </div>
        </div>
        <div className="content">
          <div className="left">
            <div className="card">
              <div className="filter">
                <div>
                  <h2>{t("page_governance_title_proposals")}</h2>
                </div>
                <div>
                  <Select defaultValue="all" onChange={handleStatusChange}>
                    <Option value="all">
                      {t("page_governance_status_all")}
                    </Option>
                    <Option value="active">
                      {t("page_governance_status_active")}
                    </Option>
                    <Option value="soon">
                      {t("page_governance_status_soon")}
                    </Option>
                    <Option value="passed">
                      {t("page_governance_status_passed")}
                    </Option>
                    <Option value="failed">
                      {t("page_governance_status_failed")}
                    </Option>
                  </Select>
                </div>
              </div>
              {proposals.map((proposal: any, index: number) => (
                <ProposalItem
                  key={proposal.id}
                  proposal={proposal}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              ))}
            </div>
            <Pagination
              current={pageNo}
              defaultPageSize={5}
              total={totalCount}
              onChange={handlePageChange}
            />
          </div>
          <div className="right">
            <div className="card">
              <h2>{t("page_governance_title_instructions")}</h2>
              <ol>
                <li>{t("page_governance_content_instructions_1")}</li>
                <li>{t("page_governance_content_instructions_3")}</li>
                <li>{t("page_governance_content_instructions_4")}</li>
                <li>{t("page_governance_content_instructions_5")}</li>
              </ol>
              <ul>
                <li>
                  {t("page_governance_tips_total_proposals")}: {totalCount}
                </li>
                <li>
                  {t("page_governance_tips_passed_proposals")}: {passedCount}
                </li>
                <li>
                  {t("page_governance_tips_failed_proposals")}: {failedCount}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
}
