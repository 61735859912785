import { configureStore } from '@reduxjs/toolkit'

import application from './application/reducer'
import transactions from './transactions/reducer'
import multicall from './multicall/reducer'

const store = configureStore({
  reducer: {
    application,
    transactions,
    multicall,
  }
})


export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
