import { useCallback, useEffect, useState } from 'react';
import { get, request } from 'utils/request';
import { API_URL } from 'constants/index';
import { getSign, handleIsSignExpired } from 'utils/txSign';
import useActiveWeb3React from "hooks/useActiveWeb3React";

export function useParticipatedList(pageNo: number, pageSize: number): any{
    const [list, setList] = useState<any>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async() => {
        const header: any = await getSign(library, account, false)
        let params: any = {
            pageNo,
            pageSize
        }
        let query = Object.keys(params)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
        get(`${API_URL}/projects/participated?${query}`, header).then((res: any) => {
            if(res && res.data){
                setList(res.data.list)
                setTotalCount(res.data.totalCount)
            }
        })
    }, [library, account, pageNo, pageSize])
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account, pageNo, pageSize])
    return {list, totalCount};
}

export function useCryptoCurrency(symbol: string): any {
    const [price, setPrice] = useState<any>();
    const fetchData = useCallback(async() => {
        get(`${API_URL}/cmc/cryptocurrency/quotes/latest?symbol=${symbol}`).then((res: any) => {
            if(res && res.data){
                setPrice(res.data.data[symbol.toUpperCase()].quote.USD.price)
            }
        })
    }, [])
    useEffect(() => {
        if(symbol){
            fetchData()
        }
    },[symbol])
    return price;
}