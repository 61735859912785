import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TwitterOutlined, GithubOutlined } from "@ant-design/icons";
import { ReactComponent as TelegramLogo } from "assets/img//Telegram.svg";
import { ReactComponent as MediumLogo } from "assets/img//Medium.svg";
import { ReactComponent as GitbookLogo } from "assets/img//gitbook-icon.svg";
import { ReactComponent as DiscordLogo } from "assets/img//discord-icon.svg";

import WechatQR from "../../assets/images/home/QR.jpeg";
import { setLangLinks } from "utils/setLanguageLinks";

const FooterContent = styled.div`
  background: #4f545e;
  display: flex;
  justify-content: space-around;
  color: #fff;
  padding: 30px;
  font-size: 16px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      display: block;
      margin: 0 10px;
      a {
        display: flex;
        align-items: center;
      }
      svg {
        display: block;
        fill: #fff;
        height: 24px;
        width: 24px;
      }
    }
  }
`;

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FooterContent>
      <div>
        {t("footer_copyright")}
        <br />
        {t("footer_copyright_address")}
      </div>
      <div></div>
    </FooterContent>
  );
};

export default Footer;
