import { useState } from "react";
import {
  Layout,
  Input,
  Avatar,
  Progress,
  Statistic,
  Skeleton,
  Button,
} from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import moment from "moment";
import { BigNumber } from "@ethersproject/bignumber";
import { useTranslation } from "react-i18next";
import { formatUnits } from "@ethersproject/units";
import { floatDiv } from "utils/calculate";
import { useProjectList } from "./hooks";
import { ReactComponent as Logo } from "assets/img/Logo.svg";
import { ReactComponent as LogoSingle } from "assets/img/logo-single.svg";
import { ReactComponent as SearchIcon } from "assets/img/search.svg";
import { setLangLinks } from "utils/setLanguageLinks";
import "./index.less";
import { ReactComponent as WebsiteIcon } from "assets/img/website_icon.svg";
import { ReactComponent as TwitterIcon } from "assets/img/twitter_icon.svg";
import { ReactComponent as DiscordIcon } from "assets/img/discord_icon.svg";
import { ReactComponent as MediumIcon } from "assets/img/medium_icon.svg";

const { Content } = Layout;
const { Search } = Input;
const { Countdown } = Statistic;

const Item = ({ data, onFinish }: any) => {
  const history = useHistory();
  const percent =
    Object.keys(data).length > 0
      ? data?.soldTotalPrice === "0"
        ? 0
        : floatDiv(data.soldTicketCount, data.ticketCount) * 100
      : 0;
  const { t } = useTranslation();

  return Object.keys(data).length > 0 ? (
    <div
      className="item"
      key={data.projectId}
      onClick={() => {
        history.push(`/project/${data.projectId}`);
      }}
    >
      {/* Close 태그 제거 */}
      {/* <Tag className={data.status}>
        {t(`page_projects_status_${data.status}`)}
      </Tag> */}
      {data.status !== "closed" && (
        <div className="img">
          {data.banner ? (
            <img src={data.banner} alt="banner" />
          ) : (
            <div className="noBanner">
              <Logo />
            </div>
          )}
        </div>
      )}
      <div className="avatar">
        <div>
          <Avatar
            shape="square"
            icon={!data?.tokenInfo?.image && <LogoSingle />}
            size={60}
            src={data?.tokenInfo?.image}
          />
        </div>
        <div>
          <h2>{data.title}</h2>
          <h3>{data?.tokenInfo?.symbol}</h3>
        </div>
      </div>
      <div className="intro">
        {/* <p>{data.description}</p> */}
        <ul>
          {data.website && (
            <li onClick={(e) => e.stopPropagation()}>
              <a
                className="icon"
                href={data.website}
                target="_blank"
                rel="noreferrer"
              >
                <WebsiteIcon />
              </a>
            </li>
          )}
          {data.twitter && (
            <li onClick={(e) => e.stopPropagation()}>
              <a
                className="icon"
                href={data.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon />
              </a>
            </li>
          )}
          {data.discord && (
            <li onClick={(e) => e.stopPropagation()}>
              <a
                className="icon"
                href={data.discord}
                target="_blank"
                rel="noreferrer"
              >
                <DiscordIcon />
              </a>
            </li>
          )}
          {data.medium && (
            <li onClick={(e) => e.stopPropagation()}>
              <a
                className="icon medium"
                href={data.medium}
                target="_blank"
                rel="noreferrer"
              >
                <MediumIcon />
              </a>
            </li>
          )}
        </ul>
        <Progress
          strokeColor={{
            "0%": "#4A86F0",
            "100%": "#733CE9",
          }}
          percent={percent}
          format={(percent) => `${percent}%`}
        />
        <div className="footer">
          <div>
            {formatUnits(BigNumber.from(data.soldTotalPrice), 18)}{" "}
            {data.priceToken.symbol} ({percent}%)
          </div>
          <div>
            {data.status === "soon" && (
              <Countdown
                value={data.startTime}
                prefix={t("page_projects_label_open_at")}
                format="D[d] H[h] m[m] s[s]"
                onFinish={onFinish}
              />
            )}
            {data.status === "open" && (
              <Countdown
                value={data.endTime}
                prefix={t("page_projects_label_close_in")}
                format="D[d] H[h] m[m] s[s]"
                onFinish={onFinish}
              />
            )}
            {data.status === "closed" &&
              `${t("page_projects_label_closed_at")} ${moment(
                data.endTime
              ).format("YYYY-MM-DD HH:mm:ss")}`}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Skeleton avatar paragraph={{ rows: 4 }} active />
  );
};

export default function Projects() {
  const [refesh, setRefresh] = useState<number>(0);
  const { list: openList } = useProjectList("open", 1, 20, false, refesh);
  const { list: soonList } = useProjectList("soon", 1, 20, false, refesh);
  const [pageNo, setPageNo] = useState(1);
  const { list: closedList, totalCount: closedTotal } = useProjectList(
    "closed",
    pageNo,
    20,
    false,
    refesh
  );
  const [searchText, setSearchText] = useState("");
  const { list: searchList } = useProjectList("all", 1, 20, searchText, refesh);
  const { t } = useTranslation();
  const onSearch = async (value: any) => {
    setSearchText(value);
  };
  const onFinish = () => {
    setRefresh(refesh + 1);
  };

  const openGuide = () => {
    setLangLinks({
      koLink:
        "https://docs.cbkstarter.com/user-manual/project-pool/enter-project-pool-kor",
      enLink:
        "https://docs.cbkstarter.com/user-manual/project-pool/enter-project-pool-eng",
    });
  };

  const openSchedule = () => {
    setLangLinks({
      koLink:
        "https://medium.com/cobak/%EC%BD%94%EB%B0%95-%EA%B8%80%EB%A1%9C%EB%B2%8C-ido-%ED%94%8C%EB%9E%AB%ED%8F%BC-%EA%B3%B5%EA%B0%9C-638d33938719",
      enLink:
        "https://medium.com/cobak/cobaks-global-ido-launching-day-3521e6cf315a",
    });
  };

  const [language, setLanguage] = useState(localStorage.getItem("cobak_lang"));

  return (
    <div className="projects">
      <div className="first-content">
        <Content>
          <h1>{t("page_projects_title")}</h1>
          <p>
            {t("page_projects_subtitle")}{" "}
            <a onClick={openGuide}>{t("page_projects_subtitle_learn_more")}</a>
          </p>
          <div className="search">
            <div className="search-input">
              <Search
                placeholder={t("page_projects_search_placeholder")}
                allowClear
                onSearch={onSearch}
                enterButton={<SearchIcon />}
              />
            </div>
          </div>
        </Content>
      </div>
      <Content>
        {searchText && (
          <>
            <h1>
              {t("page_projects_search_tips", {
                length: searchList.length,
                search: searchText,
              })}
            </h1>
            <div className="content">
              {searchList.map((data: any) => (
                <Item key={data.projectId} data={data} />
              ))}
            </div>
          </>
        )}
        {!searchText && (
          <>
            {openList.length > 0 && <h1>{t("page_projects_title_ongoing")}</h1>}
            {openList.length > 0 && (
              <div className="content">
                {openList.map((data: any) => (
                  <Item key={data.projectId} data={data} onFinish={onFinish} />
                ))}
              </div>
            )}
            {soonList.length > 0 && (
              <h1>{t("page_projects_title_upcoming")}</h1>
            )}
            {soonList.length > 0 && (
              <div className="content">
                {soonList.map((data: any) => (
                  <Item key={data.projectId} data={data} onFinish={onFinish} />
                ))}
              </div>
            )}
            {closedList.length > 0 && (
              <h1>{t("page_projects_title_finished")}</h1>
            )}
            {closedList.length > 0 && (
              <>
                <div className="content">
                  {closedList.map((data: any) => (
                    <Item key={data.projectId} data={data} />
                  ))}
                </div>
                {pageNo < closedTotal / 20 && (
                  <Button
                    style={{ textAlign: "center", margin: "20px 0" }}
                    type="link"
                    block
                    onClick={() => {
                      setPageNo(pageNo + 1);
                    }}
                  >
                    Load more
                    <DoubleRightOutlined rotate={90} />
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </Content>
    </div>
  );
}
