import { Modal, Button, message } from 'antd';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next'
import { formatUnits } from "@ethersproject/units";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { postVoteProposal } from './hooks';

import './index.less'
const ConfirmModal = ({ visible, handleCancel, data, selectOption }: any) => {
  const { account, library } = useActiveWeb3React();
  const { t } = useTranslation()

  const cofirm = async () => {
    const res = await postVoteProposal(data.id, selectOption, data?.userVoteInfo?.totalCount, account, library);
    if (res?.code === 'success') {
      message.success('Vote success');
      handleCancel()
    }
  }
  return <Modal className='confirmModal' title={t('modal_title_confirm_vote2')} okText={t('modal_button_confirm')} cancelText={t('modal_button_cancel')} visible={visible} onCancel={handleCancel} onOk={cofirm} >
    <h2>{t('modal_label_voting_for')}</h2>
    <div className='option'>
      {selectOption}. {data?.options?.[selectOption - 1]?.content}
    </div>
    <div className='count'>
      <div>{t('page_governance_label_your_voted')}</div>
      <div>{data?.userVoteInfo?.totalCount && numeral(formatUnits(data?.userVoteInfo?.totalCount, 18)).format('0,0')}</div>
    </div>
    <div className='notice'>{t('modal_tips_votes')}</div>
  </Modal >
}

export default ConfirmModal