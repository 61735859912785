import { useState } from 'react';
import { Button, Tag, Statistic, Popover } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next'
import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from "@ethersproject/units";
import { TransactionResponse } from '@ethersproject/providers'
import { useCobakPoolContract } from 'hooks/useContract'
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useTransactionAdder } from "state/transactions/hooks"
import { useSingleCallResult } from 'state/multicall/hooks'
import { Pool_Address } from 'constants/index'
import { ReactComponent as MessageIcon } from 'assets/img/message.svg'

import './index.less';
import { retry, RetryableError } from 'utils/retry';

const { Countdown } = Statistic;

const TicketNum = ({ index, poolId }: any) => {
  const { account, chainId, library } = useActiveWeb3React()
  const poolContract = useCobakPoolContract(Pool_Address[chainId || 4])
  const ticket = useSingleCallResult(poolContract, 'getTicket', [account, poolId, index])?.result?.[0];
  const quantityArr = ticket?.quantity && Number(ticket?.quantity) && new Array(Number(ticket?.quantity)).fill({}) || [{}]
  return quantityArr.map((_: any, i: number) => <Tag color="#4880EE">{(Number(ticket?.ticketId) + i || '-')}</Tag>)
}

const Claim = ({ data, setRefresh }: any) => {
  const { chainId, account, library } = useActiveWeb3React()
  const poolContract = useCobakPoolContract(Pool_Address[chainId || 4])
  const addTransaction = useTransactionAdder()
  const { t } = useTranslation()
  const poolNumber = useSingleCallResult(poolContract, 'getPoolNumber', [data.poolId])?.result?.[0];
  const poolResult = useSingleCallResult(poolContract, 'getPoolResult', [data.poolId])?.result;
  const isUserClaimed = useSingleCallResult(poolContract, 'isUserClaimed', [data.poolId, account])?.result?.[0];
  const claimable = useSingleCallResult(poolContract, 'getClaimable', [data.poolId, account])?.result?.[0];
  const ticketReward = useSingleCallResult(poolContract, 'getTicketReward', [account, data.poolId])?.result;
  const poolClaimed = useSingleCallResult(poolContract, 'poolClaimed', [data.poolId])?.result?.[0];
  const fee = useSingleCallResult(poolContract, 'fee')?.result?.[0];
  const ownerAvailableClaim = useSingleCallResult(poolContract, 'getOwnerAvailableClaim', [data.poolId])?.result;
  const boughtNum = useSingleCallResult(poolContract, 'getBought', [data.poolId, account])?.result?.[0];
  const ticketLength = useSingleCallResult(poolContract, 'getTicketLength', [account, data.poolId])?.result?.[0];
  const ticketsArr = ticketLength && Number(ticketLength) && new Array(Number(ticketLength)).fill({});
  const tokenDecimals = data?.tokenInfo?.decimals;
  const [loading, setLoading] = useState(false);

  const { wonTicketNumber, boughtTicketNumber } = data?.userIdoInfo;
  let isNeg;
  let winning = new Array();
  if (poolResult) {
    isNeg = poolResult[0];
    const zeroPad = (num: any, places: any) => String(num).padStart(places, '0')
    if (poolResult[1]) {
      poolResult[1].forEach((item: any, index: any) => {
        let size = item.div(Math.pow(2, 31)).toNumber()
        let n = item & 0xffff
        winning[index] = zeroPad(n, size)
      })
    }
  }

  const claim = () => {
    if (poolContract && library) {
      setLoading(true);
      poolContract.claimReward(BigNumber.from(data.poolId))
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Claim`
          });

          retry(() => {
            return library
              .getTransactionReceipt(response.hash)
              .then(receipt => {
                if (receipt === null) {
                  console.debug('Retrying for hash', response.hash)
                  throw new RetryableError()
                }
                if (receipt) {
                  console.log("trade receipt", receipt)
                  setLoading(false);
                }
              })
          }, {
            n: Infinity,
            minWait: 2500,
            maxWait: 3500
          })

        })
        .catch((error: any) => {
          setLoading(false);
          console.log(error)
        })
    }
  }
  const ownerClaim = () => {
    if (poolContract) {
      poolContract.ownerClaim(BigNumber.from(data.poolId))
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Owner Claim`
          })
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  // const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK

  return <div className="claim">
    {data?.userIdoInfo?.boughtTicketCount && data?.userIdoInfo?.maxTicketCount > 0 && <>
      {!isUserClaimed &&
        <>
          {!claimable && <span className='claim_text'>{t('page_projects_claim_text')}</span>}
          <Button
            type="primary"
            block onClick={claim}
            icon={loading && <LoadingOutlined />}
            disabled={loading || !claimable}>
            {t('page_projects_button_claim')}
          </Button>
        </>
      }
      <div className='claim-info'>
        {ticketReward && ticketReward[0] && Number(formatUnits(ticketReward[0], tokenDecimals)) > 0 && (claimable || isUserClaimed) &&
          <div>
            <div className='ticket_label'>{t('page_projects_label_winning_tickets')}</div>
            {
              wonTicketNumber === null &&
              <>
                <span>{t('page_projects_winning_ticket_waiting')}</span>
                <a className='refresh' onClick={setRefresh}><ReloadOutlined style={{ color: 'white' }} /></a>
              </>
            }
            {
              ((wonTicketNumber && wonTicketNumber.length > 0) || (isUserClaimed)) &&
              <>
                <div>
                  {
                    wonTicketNumber.map((v: any) => {
                      return <><span className={'ticket winning'}>{v}</span></>
                    })
                  }
                </div>
              </>
            }
          </div>
        }

        {(boughtNum && Number(boughtNum)) && <div>
          <div className='ticket_label'>{t('page_projects_label_my_tickets')}</div>
          <div>
            {
              boughtTicketNumber && boughtTicketNumber.length > 0 ?
                boughtTicketNumber.map((v: any) => {
                  return <><span className='ticket'>{v}</span></>
                }) : '-'
            }
          </div>
        </div>}
        <div>
          <div>{t('page_projects_label_available_claims_token_2')} ({data?.tokenInfo.symbol})</div>
          <div>{isUserClaimed ? '0' : ticketReward && ticketReward[0] && numeral(formatUnits(ticketReward[0], tokenDecimals)).format('0,0.00')}/{ticketReward && ticketReward[0] && numeral(formatUnits(ticketReward[0], tokenDecimals)).format('0,0.00')}</div>
        </div>
        <div>
          <div>{t('page_projects_label_available_claims_token_1')} ({data?.priceToken?.symbol})</div>
          <div>{isUserClaimed ? '0' : ticketReward && ticketReward[1] && numeral(formatUnits(ticketReward[1], 18)).format('0,0.0000')}/{ticketReward && ticketReward[1] && numeral(formatUnits(ticketReward[1], 18)).format('0,0.0000')}</div>
        </div>
      </div>
    </>
    }
    {
      data?.fundingAddress === account &&
      <>
        <h2>{t('page_projects_label_funds_addresss')}</h2>
        <div className='claim-info'>
          <div>
            <div>{t('page_projects_label_funds_to_raise')}</div>
            <div>{Number(formatUnits(data?.soldTotalPrice, 18)) - Number(formatUnits(data?.totalPrice, 18)) > 0 ? numeral(formatUnits(data?.totalPrice, 18)).format('0,0.0000') : numeral(formatUnits(data?.soldTotalPrice, 18)).format('0,0.0000') || '-'} {data?.priceToken?.symbol}</div>
          </div>
          <div>
            <div>{t('page_projects_label_fees')}</div>
            <div>
              {fee ? `${Number(fee)}%` : '-'}
            </div>
          </div>
          <div>
            <div>{t('page_projects_label_available_claims_token_1')} ({data?.priceToken?.symbol})</div>
            <div>{poolClaimed ? '0' : ownerAvailableClaim && ownerAvailableClaim[1] && numeral(formatUnits(ownerAvailableClaim[1], 18)).format('0,0.0000')}/{ownerAvailableClaim && ownerAvailableClaim[1] && numeral(formatUnits(ownerAvailableClaim[1], 18)).format('0,0.0000')}</div>
          </div>
          <div>
            <div>{t('page_projects_label_available_claims_token_2')} ({data?.tokenInfo?.symbol})</div>
            <div>{poolClaimed ? '0' : ownerAvailableClaim && ownerAvailableClaim[0] && numeral(formatUnits(ownerAvailableClaim[0], tokenDecimals)).format('0,0.00')}/{ownerAvailableClaim && ownerAvailableClaim[0] && numeral(formatUnits(ownerAvailableClaim[0], tokenDecimals)).format('0,0.00')}</div>
          </div>
          <Button type="primary" block onClick={ownerClaim} disabled={poolClaimed || !poolNumber || (poolNumber && poolNumber.isZero())}>
            {poolClaimed ? 'Claimed' : 'Claim assets'}
          </Button>
        </div>
      </>
    }
  </div >
}

export default Claim;
