import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { getContract } from '../utils'
import { COBAK_POOL_ABI } from 'constants/abis/CobakPool'
import { COBAK_STAKE_ABI } from 'constants/abis/CobakStake'
import { COBAK_AIRDROP_ABI } from 'constants/abis/CobakAirdrop'
import { ERC20_TOKEN_ABI } from 'constants/abis/ERC20Token'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from 'constants/multicall'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useCobakPoolContract(tokenAddress: string): Contract | null {
  return useContract(tokenAddress, COBAK_POOL_ABI, true)
}

export function useCobakStakeContract(tokenAddress: string): Contract | null {
  return useContract(tokenAddress, COBAK_STAKE_ABI, true)
}

export function useAirdropContract(tokenAddress: string): Contract | null {
  return useContract(tokenAddress, COBAK_AIRDROP_ABI, true)
}

export function useTokenContract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, ERC20_TOKEN_ABI)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}
