import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  .bg {
    background: black;
    width: 100%;
    height: 520px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .bg-img {
      position: absolute;
      fill: 100%;
      top: 0;
      object-fit: cover;
      background-repeat: none;
      width: 100%;
      height: 100%;
    }
    .overlay {
      position: absolute;
      width: 100%;
      background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      top: 80px;
      left: 0;
      height: 440px;
    }
    .intro {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: end;
      img {
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 100%;
        z-index: 2;
      }
      .intro-text {
        display: flex;
        flex-direction: column;
        z-index: 2;
        text-align: center;
        h3 {
          color: #fff;
          font-weight: 700;
          font-size: 1.7rem;
          margin: 0;
        }
        p {
          font-size: 0.875rem;
          color: #cfd6e4;
          margin-bottom: 0;
        }
      }
    }
    .social {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      z-index: 2;
      margin-bottom: 112px;
      margin-top: 34px;
      a {
        width: 2.25rem;
        height: 2.25rem;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .main {
    margin-top: 2.5rem;
    margin-bottom: 3.75rem;
    display: flex;
    justify-content: center;
    width: 100%;
    @media (max-width: 1024px) {
      padding: 0 16px;
    }
    .main-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 20px;

      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
      }
    }
    .main-info {
      width: 610px;
      display: flex;
      flex-direction: column;
      @media (max-width: 1024px) {
        width: 100%;
      }
      h3 {
        height: 1.3rem;
        display: flex;
        align-items: center;
        color: #1a1e27;
        font-weight: 700;
        font-size: 1.25rem;
      }
      .card-list {
        display: flex;
        flex-direction: column;
        gap: 1.7rem;
        .card {
          background: #f8fafd;
          border-radius: 1rem;
          padding: 1rem 1.5rem;
          width: 38.125rem;
          @media (max-width: 1024px) {
            width: 100%;
          }
          h3 {
            height: 3.125rem;
            display: flex;
            align-items: center;
            color: #353c49;
            font-weight: 700;
            font-size: 1.125rem;
          }
          .card-info {
            height: 3.125rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #eaedf0;
            &:last-child {
              border: none;
            }
            .title {
              color: #353c49;
              font-size: 0.875rem;
              font-weight: 500;
            }
            .desc {
              color: #353c49;
              font-size: 0.875rem;
              font-weight: 700;
            }
            .time {
              display: none;
              @media (max-width: 1024px) {
                display: block;
              }
            }
          }
        }
      }
    }
    .detail {
      display: flex;
      min-width: 430px;
      margin-top: 60px;
      @media (max-width: 1024px) {
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 100%;
      }

      .ant-btn {
        font-weight: 500;
        font-size: 20px;

        &[disabled] {
          background: #b8c5d8;
          color: #fff;
        }
      }

      .img {
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        .noBanner {
          height: 230px;
          background: linear-gradient(180deg, #cb33d2 0%, #4d3eda 100%);
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            height: 60px;
            width: auto;
          }
        }

        img {
          object-fit: contain;
          width: 100%;
        }
      }

      & > div {
        & > div {
          padding: 16px;
          border-radius: 16px;
          background: #fff;

          .ant-btn-default {
            background: #dcdcdc;
            border-radius: 28px;
            color: #697483;
          }

          h3 {
            margin-top: 30px;
          }
        }
      }

      .left-content {
        width: 100%;
        .ant-btn {
          height: 52px;
        }

        .ant-tag {
          display: block;
          width: 100%;
          background: #e9e9e9;
          border-radius: 28px;
          height: 56px;
          color: #1a1e27;
          font-size: 20px;
          border: none;
          line-height: 56px;
          text-align: center;
          margin-bottom: 20px;
        }

        .no_wthielist {
          padding-left: 0;
          list-style-position: inside;
          list-style: none;

          li {
            margin-bottom: 20px;

            .ant-btn {
              margin-top: 10px;
            }
          }

          p {
            margin: 20px 0;
          }
        }

        .info {
          /* background: #f7f7f7; */
          border-radius: 16px;
          padding: 10px 20px;

          & > div {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;

            & > div {
              &:first-of-type {
                color: #1a1e27;
                font-size: 16px;
                font-weight: 400;
              }

              &:last-of-type {
                color: #1a1e27;
                font-size: 16px;
                font-weight: 500;

                .ant-statistic {
                  line-height: 1;
                }

                .ant-statistic-content-value {
                  color: #1a1e27;
                  font-size: 16px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
`;
