import { Modal } from 'antd';
import {ReactComponent as Link} from 'assets/img/link.svg'
import {ReactComponent as Logo} from 'assets/img/logo-single.svg'


const BuyCBKModal = ({visible, handleCancel}: any) => {

    return <Modal className='buyCBKModal' width={400} footer={null} title={<><Logo/>Buy CBK</>} visible={visible} onCancel={handleCancel}>
        <a href='https://www.gate.io/trade/cbk_usdt' target="_blank" className='item'>
            <div>Buy CBK On Gate.io</div>
            <div><Link/></div>
        </a>
        <a href='https://www.mexc.com/exchange/CBK_USDT' target="_blank" className='item'>
            <div>Buy CBK On MEXC</div>
            <div><Link/></div>
        </a>
        <a href='https://upbit.com/exchange?code=CRIX.UPBIT.BTC-CBK' target="_blank" className='item'>
            <div>Buy CBK On Upbit</div>
            <div><Link/></div>
        </a>
  </Modal>
}

export default BuyCBKModal