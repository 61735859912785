import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { Layout, Button, Table, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import moment from 'moment';
import { useHistory } from "react-router";
import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from "@ethersproject/units";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import Copy from 'components/AccountDetails/Copy'
import { useCobakStakeContract, useTokenContract } from 'hooks/useContract'
import { useSingleCallResult } from 'state/multicall/hooks'
import { Cobak_Address, Stake_Address, walletconnect } from 'constants/index'
import { useParticipatedList, useCryptoCurrency } from './hooks';
import { useTiers } from '../Staking/hooks';
import { floatMul, floatAdd } from 'utils/calculate';
import { getEtherscanLink, shortenAddress } from 'utils'
import { clearLocalStorage } from 'utils/txSign'
import { useWalletModalToggle } from 'state/application/hooks'
import { ReactComponent as Logo } from 'assets/img/logo-single.svg'
import ETH from 'assets/img/eth.png'
import { ReactComponent as WalletIcon } from 'assets/img/wallet.svg'
import './index.less'

const { Content } = Layout;
const { confirm } = Modal;

export default function Wallet() {
  const history = useHistory();
  const { account, chainId, library, deactivate, connector } = useActiveWeb3React();
  const { t } = useTranslation()
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const accountArg = useMemo(() => [account ?? undefined], [account])
  const stakeContract = useCobakStakeContract(Stake_Address[chainId || 4]);
  const stakebalance = useSingleCallResult(stakeContract, 'balances', accountArg)?.result?.[0];
  const cbkContract = useTokenContract(Cobak_Address[chainId || 4])
  const cbkBalance = useSingleCallResult(cbkContract, 'balanceOf', accountArg)?.result?.[0];
  const [ethBalance, setEthBalance] = useState<any>();
  const [pageNo, setPageNo] = useState<number>(1);
  const { list: participatedList, totalCount } = useParticipatedList(pageNo, 5);
  const ethPrice = useCryptoCurrency('eth');
  const cbkPrice = useCryptoCurrency('cbk');
  const tiers = useTiers()
  const assets = [{ symbol: 'eth', price: ethPrice, balance: ethBalance }, { symbol: 'cbk', price: cbkPrice, balance: cbkBalance }]
  const totalAsset = ethBalance && ethPrice && cbkBalance && cbkPrice && stakebalance ? `$${numeral(floatAdd(floatAdd(floatMul(Number(formatUnits(BigNumber.from(ethBalance), 18)), ethPrice), floatMul(Number(formatUnits(BigNumber.from(cbkBalance), 18)), cbkPrice)), floatMul(Number(formatUnits(BigNumber.from(stakebalance), 18)), cbkPrice))).format('0,0.00')}` : '-'
  const toggleWalletModal = useWalletModalToggle()
  const stakeNum = stakebalance && Number(formatUnits(stakebalance, 18))
  let level = 0;
  if (stakeNum > 0 && tiers.length > 0) {
    const index = tiers.findIndex((v: any) => Number(stakeNum) >= Number(v.count))
    level = index >= 0 ? tiers[index]?.tier : 0;
  }
  const staking = [{ amount: stakebalance, symbol: 'cbk', price: cbkPrice, level: level }]

  const handlePageChange = (pagination: any) => {
    setPageNo(pagination.current);
  }

  const disconnect = () => {
    deactivate();
    clearLocalStorage()
    if (connector === walletconnect) {
      //@ts-ignore
      connector.close();
    }
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery: any) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    console.log('running')
    if (library && account) {
      let stale = false;

      library
        .getBalance(account)
        .then((balance: any) => {
          if (!stale) {
            setEthBalance(balance);
          }
        })
        .catch(() => {
          if (!stale) {
            setEthBalance(null);
          }
        });

      return () => {
        stale = true;
        setEthBalance(undefined);
      };
    }
  }, [library, account, chainId])

  const assetsColumns = [
    {
      title: t('page_wallet_assets_tr_assets'),
      dataIndex: 'symbol',
      key: 'symbol',
      with: 120,
      render: (v: any) => <div className='asset'>{v === 'eth' ? <img src={ETH} /> : <Logo />}{v.toUpperCase()}</div>
    },
    {
      title: t('page_wallet_assets_tr_balance'),
      dataIndex: 'balance',
      key: 'balance',
      with: 150,
      render: (v: any, row: any) => { return v ? `${numeral(formatUnits(BigNumber.from(v), 18)).format('0,0.0000')} ${row.symbol.toUpperCase()}` : '-' }
    },
    {
      title: t('page_wallet_assets_tr_price'),
      dataIndex: 'price',
      key: 'price',
      with: 120,
      render: (v: any) => `$${numeral(v).format('0,0.00')}`
    },
    {
      title: t('page_wallet_assets_tr_value'),
      dataIndex: 'value',
      key: 'value',
      with: 150,
      render: (_: any, row: any) => row.balance && row.price ? `$${numeral(floatMul(Number(formatUnits(BigNumber.from(row.balance), 18)), row.price)).format('0,0.00')}` : '-'
    },
  ];
  const stakingColumns = [
    {
      title: t('page_wallet_staking_tr_name'),
      dataIndex: 'level',
      key: 'level',
      with: 250,
    },
    {
      title: t('page_wallet_staking_tr_amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (v: any, row: any) => v && `${numeral(formatUnits(v, 18)).format('0,0.00')} ${row.symbol.toUpperCase()}` || '-'
    },
    {
      title: t('page_wallet_staking_tr_value'),
      dataIndex: 'Value',
      key: 'value',
      render: (_: any, row: any) => row.amount && row.price ? `$${numeral(floatMul(Number(formatUnits(BigNumber.from(row.amount), 18)), row.price)).format('0,0.00')}` : '-'
    }, {
      title: t('page_wallet_staking_tr_operations'),
      dataIndex: 'Operations',
      key: 'Operations',
      render: () => <Button type="link" onClick={() => { history.push('/staking') }}>{t('page_wallet_staking_button_operations')}</Button>
    },
  ];
  const applicationsColumns = [
    {
      title: t('page_wallet_applications_tr_project_name'),
      dataIndex: 'title',
      key: 'title',
      with: 150,
      ellipsis: true,
      render: (v: any) => <div style={{ wordBreak: 'break-all' }}>{v}</div>
    },
    {
      title: t('page_wallet_applications_tr_status'),
      dataIndex: 'status',
      key: 'status',
      with: 60,
      render: (v: any) => t(`page_projects_status_${v}`)
    },
    {
      title: t('page_wallet_applications_tr_date'),
      dataIndex: 'endTime',
      key: 'endTime',
      with: 120,
      render: (v: any) => moment(v).format('YYYY-MM-DD HH:mm:ss')
    },
  ];

  const showConfirm = () => {
    confirm({
      title: t('disconnect_title'),
      icon: <ExclamationCircleOutlined />,
      okText: t('disconnect_ok_text'),
      cancelText: t('disconnect_cancel_text'),
      onOk() {
        disconnect();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  return (
    <div className="wallet-page">
      <Content>
        <h1>{t('page_wallet_title')}</h1>
        <div className='info'>
          <div className='left-content'>
            <div className='address'>
              <div><WalletIcon className='wallet' /></div>
              <div>
                {account &&
                  <>
                    <div className='address_link'>{account && shortenAddress(account, 8)}</div>
                    <Copy toCopy={account}></Copy>
                  </>
                }
              </div>
            </div>
            <div className='btns'>
              {chainId && account && <Button type="default" block><a href={getEtherscanLink(chainId, account, 'address')}>{t('page_wallet_button_view_blockchain')}</a></Button>}
              {account && <Button danger block onClick={showConfirm}>{t('page_wallet_button_disconect')}</Button>}
              {!account && <Button type="default" onClick={toggleWalletModal} block>{t('conect_wallet')}</Button>}
            </div>
          </div>
          <div className='assets'>
            <div>{t('page_wallet_label_total_assets')}</div>
            <div>{totalAsset}</div>
          </div>
        </div>
        <h2>{t('page_wallet_assets_label')}</h2>
        <Table dataSource={assets} rowKey="symbol" columns={assetsColumns} pagination={false} />
        <h2>{t('page_wallet_staking_label')}</h2>
        <Table dataSource={staking} rowKey="symbol" columns={stakingColumns} pagination={false} />
        <h2>{t('page_wallet_applications_label')}</h2>
        <Table
          dataSource={participatedList}
          columns={applicationsColumns}
          pagination={{ total: totalCount, pageSize: 5 }}
          onChange={handlePageChange}
          rowKey="projectId"
          onRow={record => {
            return {
              onClick: event => { history.push(`/project/${record?.projectId}`) }
            }
          }}
        />
      </Content>
    </div>
  )
}
