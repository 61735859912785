import { useState, useMemo, useEffect } from 'react';
import { Layout, InputNumber, Button, Statistic, Radio } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { TokenAmount, JSBI } from '@uniswap/sdk';
import { TransactionResponse } from '@ethersproject/providers';
import { parseUnits, formatUnits } from '@ethersproject/units';
import {
  useAirdropContract,
  useCobakStakeContract,
  useTokenContract,
} from 'hooks/useContract';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useApproveCallback, ApprovalState } from 'hooks/useApproveCallback';
import { useSingleCallResult } from 'state/multicall/hooks';
import {
  isTransactionRecent,
  useAllTransactions,
  useTransactionAdder,
} from 'state/transactions/hooks';
import { retry, RetryableError } from 'utils/retry';
import { floatSub } from 'utils/calculate';
import {
  Airdrop_Address,
  Stake_Address,
  Cobak_Address,
  CBK,
  NETWORK_CHAIN_ID,
} from 'constants/index';
import { useWalletModalToggle } from 'state/application/hooks';
import { useTiers } from './hooks';
import BuyCBKModal from './BuyCBKModal';
import './index.less';
import { BigNumber } from 'ethers';
import { setLangLinks } from 'utils/setLanguageLinks';
import { TransactionDetails } from 'state/transactions/reducer';

const { Content } = Layout;
const { Countdown } = Statistic;

export default function Staking() {
  const [value, setValue] = useState('stake');
  const { chainId, account, library } = useActiveWeb3React();
  const { t } = useTranslation();
  const [amount, setAmount] = useState(5000);
  const [stakeMin, setStakeMin] = useState(0);
  const [stakeMax, setStakeMax] = useState(Infinity);
  const [unstakeMin, setUnstakeMin] = useState(0);
  const [unstakeMax, setUnstakeMax] = useState(Infinity);
  const accountArg = useMemo(() => [account ?? undefined], [account]);
  const stakeContract = useCobakStakeContract(Stake_Address[chainId || 4]);
  const stakebalance = useSingleCallResult(
    stakeContract,
    'balances',
    accountArg
  )?.result?.[0];
  const airdropContract = useAirdropContract(Airdrop_Address[chainId || 4]);
  const cbkContract = useTokenContract(Cobak_Address[chainId || 4]);

  const cbkBalance = useSingleCallResult(cbkContract, 'balanceOf', accountArg)
    ?.result?.[0];

  const [approval, approveCallback, isLoading] = useApproveCallback(
    new TokenAmount(
      CBK[chainId || 4],
      JSBI.BigInt(
        (
          BigInt(parseInt(amount.toString())) * BigInt('1000000000000000000')
        ).toString()
      )
    ),
    Stake_Address[chainId || 4]
  );

  const addTransaction = useTransactionAdder();
  const toggleWalletModal = useWalletModalToggle();
  const [showBuyCBKModal, setShowBuyCBKModal] = useState(false);
  const [isStakeLoading, setIsStakeLoading] = useState(false);
  const tiers = useTiers();
  const stakeNum =
    (stakebalance && Number(formatUnits(stakebalance, 18)).toFixed(2)) || 0;
  let level = 0;
  let tickets = 0;
  if (stakeNum > 0 && tiers.length > 0) {
    const index = tiers.findIndex(
      (v: any) => Number(stakeNum) >= Number(v.count)
    );
    level = index >= 0 ? tiers[index]?.tier : 0;
    tickets = index >= 0 ? tiers[index]?.ticketCount : 0;
  }

  console.log('level: ', level);
  console.log('stakeNum: ', stakeNum);
  const onChange = (e: any) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
    return b.addedTime - a.addedTime;
  }
  const allTransactions = useAllTransactions();
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);
  const pending = sortedRecentTransactions
    .filter((tx) => !tx.receipt)
    .map((tx) => tx.hash);
  const hasPendingTransactions = !!pending.length;

  const openGuide = () => {
    setLangLinks({
      koLink:
        'https://docs.cbkstarter.com/user-manual/staking/how-to-stake-kor',
      enLink:
        'https://docs.cbkstarter.com/user-manual/staking/how-to-stake-eng',
    });
  };

  useEffect(() => {
    if (tiers[tiers.length - 1]?.count && stakebalance && cbkBalance) {
      if (value === 'stake') {
        setAmount(tiers[tiers.length - 1]?.count);
        setStakeMin(
          stakeNum >= tiers[tiers.length - 1]?.count
            ? 1
            : tiers[tiers.length - 1]?.count
        );
        setStakeMax(Number(formatUnits(cbkBalance, 18)));
      } else {
        setAmount(stakeNum);
        setUnstakeMin(1);
        setUnstakeMax(Number(formatUnits(stakebalance, 18)));
      }
    }
  }, [
    tiers.length,
    value,
    stakebalance && formatUnits(stakebalance, 18),
    cbkBalance && formatUnits(cbkBalance, 18),
  ]);

  const stake = () => {
    setIsStakeLoading(true);
    if (approval === ApprovalState.APPROVED) {
      if (library && stakeContract) {
        stakeContract
          .stake(parseUnits(amount.toString(), 18))
          .then((response: TransactionResponse) => {
            console.log('response:', response);
            addTransaction(response, {
              summary: `Stake`,
            });
            setIsStakeLoading(false);
            retry(
              () => {
                return library
                  .getTransactionReceipt(response.hash)
                  .then((receipt) => {
                    if (receipt === null) {
                      console.debug('Retrying for hash', response.hash);
                      throw new RetryableError();
                    }
                    if (receipt) {
                      console.log('trade receipt', receipt);
                    }
                    setIsStakeLoading(false);
                  });
              },
              {
                n: Infinity,
                minWait: 2500,
                maxWait: 3500,
              }
            );
          })
          .catch((error: any) => {
            console.log(error);
            setIsStakeLoading(false);
          });
      }
    }
  };

  const claimTestToken = () => {
    if (library && airdropContract) {
      airdropContract
        .claim()
        .then((response: TransactionResponse) => {
          console.log('response:', response);
          addTransaction(response, {
            summary: `Claim Airdrop`,
          });
          retry(
            () => {
              return library
                .getTransactionReceipt(response.hash)
                .then((receipt) => {
                  if (receipt === null) {
                    console.debug('Retrying for hash', response.hash);
                    throw new RetryableError();
                  }
                  if (receipt) {
                    console.log('trade receipt', receipt);
                  }
                });
            },
            {
              n: Infinity,
              minWait: 2500,
              maxWait: 3500,
            }
          );
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const unstake = () => {
    setIsStakeLoading(true);
    if (library && stakeContract) {
      if (amount === stakebalance && formatUnits(stakebalance, 18).toString()) {
        stakeContract
          .withdrawAll()
          .then((response: TransactionResponse) => {
            console.log('response:', response);
            addTransaction(response, {
              summary: `Unstake`,
            });
            setIsStakeLoading(false);
            retry(
              () => {
                return library
                  .getTransactionReceipt(response.hash)
                  .then((receipt) => {
                    if (receipt === null) {
                      console.debug('Retrying for hash', response.hash);
                      throw new RetryableError();
                    }
                    if (receipt) {
                      console.log('trade receipt', receipt);
                    }
                    setIsStakeLoading(false);
                  });
              },
              {
                n: Infinity,
                minWait: 2500,
                maxWait: 3500,
              }
            );
          })
          .catch((error: any) => {
            console.log(error);
            setIsStakeLoading(false);
          });
      } else {
        stakeContract
          .withdraw(parseUnits(amount.toString(), 18))
          .then((response: TransactionResponse) => {
            console.log('response:', response);
            addTransaction(response, {
              summary: `Unstake`,
            });
            setIsStakeLoading(false);
            retry(
              () => {
                return library
                  .getTransactionReceipt(response.hash)
                  .then((receipt) => {
                    if (receipt === null) {
                      console.debug('Retrying for hash', response.hash);
                      throw new RetryableError();
                    }
                    if (receipt) {
                      console.log('trade receipt', receipt);
                    }
                    setIsStakeLoading(false);
                  });
              },
              {
                n: Infinity,
                minWait: 2500,
                maxWait: 3500,
              }
            );
          })
          .catch((error: any) => {
            console.log(error);
            setIsStakeLoading(false);
          });
      }
    }
  };

  return (
    <div className="staking">
      <BuyCBKModal
        visible={showBuyCBKModal}
        handleCancel={() => {
          setShowBuyCBKModal(false);
        }}
      />
      <div className="first-content">
        <Content>
          <h1>{t('page_staking_title')}</h1>
          <p>{t('page_staking_subtitle')}</p>
        </Content>
      </div>
      <Content>
        <div className="content">
          <div className="left-content">
            <div className="btns">
              <div>
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    setShowBuyCBKModal(true);
                  }}
                >
                  {t('page_staking_button_buy')}
                </Button>
              </div>
              {NETWORK_CHAIN_ID !== 1 && (
                <div>
                  <Button
                    type="default"
                    block
                    onClick={() => {
                      claimTestToken();
                    }}
                  >
                    Get Test Token
                  </Button>
                </div>
              )}
              <div>
                <Button type="default" block onClick={openGuide}>
                  {t('page_staking_button_learn_more')} <RightOutlined />
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="stake_number">
                <div>{t('page_staking_label_my_staked_cbk')}</div>
                <div>{account ? numeral(stakeNum).format('0,0.00') : '--'}</div>
              </div>
              <div className="rank">
                <div>{t('page_staking_label_my_power_rank')}</div>
                <div>{level}</div>
              </div>
              <div className="privileges">
                <div>{t('page_staking_label_my_current_privileges')}</div>
                <div>
                  <ul>
                    <li>
                      {t('page_projects_label_tickets')}: {tickets}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="right-content">
            <div className="card">
              <Radio.Group onChange={onChange} value={value}>
                <Radio.Button value={'stake'}>
                  {t('page_staking_switch_stake')}
                </Radio.Button>
                <Radio.Button value={'unstake'}>
                  {t('page_staking_switch_unstake')}
                </Radio.Button>
              </Radio.Group>
              <div className="stake">
                <p>
                  {t('page_staking_tips_stake', {
                    amount: tiers[tiers.length - 1]
                      ? numeral(tiers[tiers.length - 1].count).format('0,0')
                      : '',
                  })}
                </p>
                <div className="amount">
                  <div>
                    <div>{t('page_staking_label_amount')}</div>
                    <div>
                      {value === 'stake'
                        ? `${t('page_staking_label_balance')}: ${
                            (cbkBalance &&
                              numeral(
                                Math.floor(
                                  Number(formatUnits(cbkBalance, 18))
                                ).toString()
                              ).format('0,0.00')) ||
                            '-'
                          }`
                        : `${t('page_staking_label_available')}: ${
                            (stakebalance &&
                              numeral(
                                formatUnits(stakebalance, 18).toString()
                              ).format('0,0.00')) ||
                            '-'
                          }`}
                    </div>
                  </div>
                  <div>
                    <div>
                      <InputNumber
                        value={amount}
                        onChange={(value: number) => {
                          if (value == undefined) {
                            setAmount(0);
                            return;
                          }
                          setAmount(parseInt(value.toString()));
                        }}
                        min={value === 'stake' ? stakeMin : unstakeMin}
                        max={value === 'stake' ? stakeMax : unstakeMax}
                      />
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setAmount(
                            value === 'stake'
                              ? cbkBalance &&
                                  Math.floor(
                                    Number(formatUnits(cbkBalance, 18))
                                  )
                              : stakeNum
                          );
                        }}
                      >
                        {t('page_staking_button_max')}
                      </Button>
                    </div>
                    <div>CBK</div>
                  </div>
                </div>
                {/* {approval !== ApprovalState.APPROVED && value === "stake" && (
                  <div className="approval_text">
                    {t("page_staking_approve_text")}
                  </div>
                )} */}

                {account &&
                  (value === 'stake' ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        approval !== ApprovalState.APPROVED
                          ? approveCallback(amount)
                          : stake();
                      }}
                      disabled={
                        !cbkBalance ||
                        cbkBalance.isZero() ||
                        hasPendingTransactions ||
                        isLoading ||
                        isStakeLoading
                      }
                      block
                    >
                      {approval !== ApprovalState.APPROVED
                        ? t('approve')
                        : t('page_staking_button_stake')}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        unstake();
                      }}
                      disabled={
                        !stakebalance ||
                        stakebalance.isZero() ||
                        hasPendingTransactions ||
                        isStakeLoading
                      }
                      block
                    >
                      {t('page_staking_button_unstake')}
                    </Button>
                  ))}
                {!account && (
                  <Button type="primary" onClick={toggleWalletModal} block>
                    {t('conect_wallet')}
                  </Button>
                )}
              </div>
            </div>
            <div className="card tiers">
              <h2>{t('page_staking_title_tiers')}</h2>
              <div className="amount">
                <div>{t('page_staking_tiers_stake_amount')}</div>
                <div>{t('page_staking_tiers_rank')}</div>
                <div>{t('page_staking_tiers_ticket_limit')}</div>
              </div>
              <div className="amount-content">
                {tiers.map((tier: any) => (
                  <div key={tier.tier}>
                    <div>{`${numeral(tier.count).format('0,0')} +`}</div>
                    <div>{tier.tier}</div>
                    <div>{numeral(tier.ticketCount).format('0,0')}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
}
