import { useState } from 'react';
import { Layout, Input, Radio, Button, Progress, Collapse, Tag, Statistic, Space } from 'antd';
import { TwitterOutlined, GithubOutlined, LeftOutlined, DownOutlined } from '@ant-design/icons';
import { RouteComponentProps } from 'react-router-dom'
import { useHistory } from "react-router";
import ReactMarkdown from "react-markdown";
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import numeral from 'numeral';
import { formatUnits } from "@ethersproject/units";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useWalletModalToggle } from 'state/application/hooks'
import { useProposalDetail, useAllVoteRecordsList } from './hooks';
import { shortenAddress } from 'utils/index'
import { floatDiv } from 'utils/calculate';
import VotesModal from './VotesModal';
import ConfirmModal from './ConfirmModal';
import './index.less'
import { BigNumber } from 'ethers';

const { Content } = Layout;
const { Panel } = Collapse;
const { Countdown } = Statistic;


const ProposalDetail = (props: RouteComponentProps<{ proposalId: string }>) => {
    const {
        match: {
            params: { proposalId }
        }
    } = props;
    const { account, chainId } = useActiveWeb3React();
    const toggleWalletModal = useWalletModalToggle();
    const [refresh, setRefresh] = useState<number>(1);
    const [pageNo, setPageNo] = useState(1);
    const data = useProposalDetail(proposalId, refresh)
    const { list, totalCount } = useAllVoteRecordsList(proposalId, pageNo, 10);
    const history = useHistory();
    const [selectOption, setSelectOption] = useState<number>(0)
    const [showVotesModal, setShowVotesModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { t } = useTranslation()

    const onChange = (e: any) => {
        console.log('radio checked', e.target.value);
        setSelectOption(e.target.value)
    };

    const voteNow = () => {
        setShowConfirmModal(true);
    }

    const onFinish = () => {
        setRefresh(refresh + 1);
    }

    const handlePageChange = (pagination: any) => {
        setPageNo(pagination.current);
    }

    return (
        <div className="proposal-detail">
            <VotesModal visible={showVotesModal} proposalId={proposalId} list={list} handlePageChange={handlePageChange} totalCount={totalCount} handleCancel={() => { setShowVotesModal(false) }} />
            <ConfirmModal visible={showConfirmModal} data={data} selectOption={selectOption} handleCancel={() => { setShowConfirmModal(false); setRefresh(refresh + 1) }} />
            <Content>
                <Button className="back-btn" onClick={() => { history.push('/proposals') }} type="default" icon={<LeftOutlined />}>{t('page_governance_button_back')}</Button>
                <div className="detail">
                    <div>
                        <div className='card'>
                            <h1>{data.title}</h1>
                            <div className="status">
                                <div className="end_time">{data?.status === 'soon' ? <Countdown onFinish={onFinish} prefix={`${t('page_staking_label_start_at')}   `} value={data.startTime} format="D[d] H[h] m[m] s[s]" /> : `${t('page_staking_label_ended_at')} ${moment(data.endTime).format('YYYY-MM-DD HH:mm:ss')}`}</div>
                                <div><Tag className={data.status}>{t(`page_governance_status_${data.status || 'soon'}`)}</Tag></div>
                                <div className='content'>
                                    <ReactMarkdown>{data.description}</ReactMarkdown>
                                </div>
                                <div className="content-h5">
                                    <Collapse
                                        expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                                    >
                                        <Panel header="" key="1">
                                            <ReactMarkdown>{data.description}</ReactMarkdown>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className='results'>
                            <h2>{t('page_governance_title_results')}</h2>
                            <div className='options'>
                                {data?.options && data?.options.map((option: any) =>
                                    <div key={option.id}>
                                        <h3>{option.id}. {option.content}</h3>
                                        <div className='percent'>
                                            <div>
                                                <Progress
                                                    strokeColor={{
                                                        '0%': '#4A86F0',
                                                        '100%': '#733CE9',
                                                    }}
                                                    percent={BigNumber.from(option.count).isZero() ? 0 : Number((floatDiv(Number(formatUnits(option?.count, 18)), Number(formatUnits(data?.totalVotes, 18))) * 100).toFixed(2))}
                                                    format={percent => `${percent}%`}
                                                />
                                            </div>
                                            <div>{numeral(formatUnits(option.count, 18)).format('0,0')} {t('modal_title_votes')}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Button type="link" block onClick={() => { setShowVotesModal(true) }}>{t('page_governance_link_view_all_votes')}({totalCount})</Button>
                        </div>
                    </div>
                    <div className="right-content">
                        {data.status !== 'passed' && data.status !== 'failed' && !Number(data?.userVoteInfo?.votedCount) &&
                            <div className='card votes'>
                                <h2>{t('page_governance_title_vote')}</h2>
                                <Radio.Group onChange={onChange} value={selectOption}>
                                    <Space direction="vertical">
                                        {data?.options && data?.options.map((option: any, index: number) =>
                                            <Radio key={option.id} value={option.id}>{option.id}. {option.content}</Radio>
                                        )}
                                    </Space>
                                </Radio.Group>
                                <div className='your_voted'>
                                    <div>{t('page_governance_label_your_vote')}</div>
                                    <div>{data?.userVoteInfo?.totalCount && numeral(formatUnits(data?.userVoteInfo?.totalCount, 18)).format('0,0')}</div>
                                </div>
                                {!account && <Button type="primary" onClick={toggleWalletModal} block>{t('conect_wallet')}</Button>}
                                {account &&
                                    <Button
                                        type="primary"
                                        block
                                        onClick={voteNow}
                                        disabled={
                                            !data?.userVoteInfo?.totalCount ||
                                            data?.userVoteInfo?.totalCount === '0' ||
                                            data?.status !== 'active'}>
                                        {t('page_governance_button_vote_note')}
                                    </Button>}
                                {data.status === 'soon' && <div className='countdown'><Countdown onFinish={onFinish} value={data.startTime} format="D[d] H[h] m[m] s[s]" /></div>}
                            </div>
                        }
                        {Number(data?.userVoteInfo?.votedCount) > 0 &&
                            <div className='card choose'>
                                <h2>{t('page_governance_label_choose')}</h2>
                                <div className='option'>
                                    {data?.userVoteInfo?.voteRecords?.[0]?.optionId}. {data?.userVoteInfo?.voteRecords?.[0]?.optionContent}
                                </div>
                                <div className='count'>
                                    <div>{t('page_governance_label_your_voted')}</div>
                                    <div>{data?.userVoteInfo?.votedCount && numeral(formatUnits(data?.userVoteInfo?.votedCount, 18)).format('0,0')}</div>
                                </div>
                            </div>
                        }
                        <div className='card details'>
                            <h2>{t('page_governance_title_details')}</h2>
                            <div className='info'>
                                <div>
                                    <div>{t('page_governance_label_snanpshot')}</div>
                                    <div>{data?.snapshot > 0 ? data?.snapshot : t('page_governance_label_snanpshot_placeholder')}</div>
                                </div>
                                <div>
                                    <div>{t('page_governance_label_start_time')}</div>
                                    <div>{moment(data.startTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                                </div>
                                <div>
                                    <div>{t('page_governance_label_end_time')}</div>
                                    <div>{moment(data.endTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content >
        </div >
    )
}

export default ProposalDetail