import React, { StrictMode, Suspense, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import store from "state";
import ThemeProvider from "theme";
import "./i18n";
import "./App.less";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Popups from "./components/Popups";
import Projects from "./views/Projects";
import ProjectDetail from "./views/Projects/ProjectDetail";
import Proposals from "./views/Governance";
import ProposalCreate from "./views/Governance/Create";
import ProposalDetail from "./views/Governance/ProposalDetail";
import Staking from "./views/Staking";
import Wallet from "views/Wallet";
import { NetworkContextName } from "./constants";
import getLibrary from "./utils/getLibrary";
import Web3ReactManager from "components/Web3ReactManager";
import ApplicationUpdater from "./state/application/updater";
import TransactionUpdater from "./state/transactions/updater";
import MulticallUpdater from "./state/multicall/updater";
import i18n from "./i18n";

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);
if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  );
}

const lang = localStorage.getItem("cobak_lang");
if (lang === "" || lang === null || lang === "undefined") {
  i18n.changeLanguage("en");
  localStorage.setItem("cobak_lang", "en");
}

function App() {
  return (
    <StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Suspense fallback={null}>
            <Provider store={store}>
              <Updaters />
              <ThemeProvider>
                <Web3ReactManager>
                  <HashRouter>
                    <Popups />
                    <Header />
                    <Switch>
                      <Route component={Projects} exact path="/" />
                      <Route component={Projects} exact path="/projects" />
                      <Route
                        component={ProjectDetail}
                        exact
                        path="/project/:projectId"
                      />
                      <Route component={Proposals} exact path="/proposals" />
                      <Route
                        component={ProposalCreate}
                        exact
                        path="/proposal/create"
                      />
                      <Route
                        component={ProposalDetail}
                        exact
                        path="/proposal/:proposalId"
                      />
                      <Route component={Staking} exact path="/staking" />
                      <Route component={Wallet} exact path="/wallet" />
                    </Switch>
                    <Footer />
                  </HashRouter>
                </Web3ReactManager>
              </ThemeProvider>
            </Provider>
          </Suspense>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </StrictMode>
  );
}

export default App;
