import { AbstractConnector } from "@web3-react/abstract-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { Token } from "@uniswap/sdk";
import { NetworkConnector } from "./NetworkConnector";

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,

  GÖRLI = 5,
  KOVAN = 42,

  RINKEBY = 4,
  MUMBAI = 80001,
  POLYGON = 137,

  SEPOLIA = 11155111,
}

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL || "";
const WALLETCONNECT_BRIDGE_URL = process.env.REACT_APP_WALLETCONNECT_BRIDGE_URL;
export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? "1"
);
export const NetworkContextName = "NETWORK";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 11155111],
});

// mainnet only
export const walletconnect = new WalletConnectConnector({
  //rpc: { 1: NETWORK_URL },
  bridge: WALLETCONNECT_BRIDGE_URL,
  qrcode: true,
  pollingInterval: 15000,
});

const PORTIS_ID = process.env.REACT_APP_PORTIS_ID;

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? "",
  networks: [1],
});

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  // INJECTED: {
  //   connector: injected,
  //   name: 'Injected',
  //   iconName: 'arrow-right.svg',
  //   description: 'Injected web3 provider.',
  //   href: null,
  //   color: '#010101',
  //   primary: true
  // },
  METAMASK: {
    connector: injected,
    name: "MetaMask",
    iconName: "metamask-fox.svg",
    description: "Easy-to-use browser extension.",
    href: null,
    color: "#E8831D",
    mobile: true,
  },
  // WALLET_CONNECT: {
  //     connector: walletconnect,
  //     name: 'WalletConnect',
  //     iconName: 'walletConnectIcon.svg',
  //     description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //     href: null,
  //     color: '#4196FC',
  //     mobile: true
  // },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
};

export const network = new NetworkConnector({
  urls: {
    1: "https://mainnet.infura.io/v3/test",
    4: "https://rinkeby.infura.io/v3/test",
    5: "https://goerli.infura.io/v3/test",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545",
    56: "https://bsc-dataseed.binance.org",
    11155111: "https://sepolia.infura.io/v3/test",
  },
  defaultChainId: NETWORK_CHAIN_ID,
});

export const API_URL = process.env.REACT_APP_API_URL;

export const Cobak_Address: any = {
  [ChainId.MAINNET]: "0xD85a6Ae55a7f33B0ee113C234d2EE308EdeAF7fD",
  [ChainId.GÖRLI]: "0xF884E6E53DaD1e2fC1eb2fc93Bf34b7059F09707",
  [ChainId.SEPOLIA]: "0xac4b2b9240569eE4CE2E62dDD31278E4da73508d",
};
export const Pool_Address: any = {
  [ChainId.MAINNET]: "0x5b4D9A7137E64c679fa75DA62feC961bf0a2683A",
  [ChainId.GÖRLI]: "0x175ECBbB2DF5Cb31b83eBf63Fed0EBBFFCC154E7",
  [ChainId.SEPOLIA]: "0xdc61472562C0bc4986Fc4Bf479D0BBa685CC805a",
};
export const Stake_Address: any = {
  [ChainId.MAINNET]: "0x1b2ed1D5C5BC15717B60868AA98a44D12FD6bb61",
  [ChainId.GÖRLI]: "0xd109a8F85CCfBD8096638aAE887b158Fa3Bd2a9B",
  [ChainId.SEPOLIA]: "0x8Acc05249675E11DcE9e1321f6fCbB73E85eD3Fc",
};

export const Airdrop_Address: any = {
  [ChainId.MAINNET]: "0x58fdd7A761B4a188eC800E6921faCD8e997394C9",
  [ChainId.GÖRLI]: "0x24c5dA235e358ca292AFAB5b5D93cD30dFF2368E",
  [ChainId.SEPOLIA]: "0x04c3AfB7B9Fda1d363dE5EA32bFC39859B9a565a",
};

export const CBK: any = {
  [ChainId.MAINNET]: new Token(1, Cobak_Address[1], 18, "CBK", "CBK Token"),
  [ChainId.SEPOLIA]: new Token(
    11155111,
    Cobak_Address[11155111],
    18,
    "TEST",
    "Test Token"
  ),
};
