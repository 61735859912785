import { useCallback, useState, useEffect, useMemo } from "react";
import { Button, Tag, Statistic, message, Popover, InputNumber } from "antd";
import numeral from "numeral";
import { useTranslation } from "react-i18next";
import { formatEther, formatUnits } from "@ethersproject/units";
import { BigNumber } from "@ethersproject/bignumber";
import {
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { TransactionResponse } from "@ethersproject/providers";
import { TokenAmount, CurrencyAmount, JSBI } from "@uniswap/sdk";
import { useCobakPoolContract, useTokenContract } from "hooks/useContract";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useApproveCallback, ApprovalState } from "hooks/useApproveCallback";
import { useSingleCallResult } from "state/multicall/hooks";
import { useQualification } from "views/Projects/hooks";
import { useTransactionAdder } from "state/transactions/hooks";
import { retry, RetryableError } from "utils/retry";
import { Cobak_Address, NETWORK_CHAIN_ID, Pool_Address } from "constants/index";
import { ReactComponent as MessageIcon } from "assets/img/message.svg";
import "./index.less";

const { Countdown } = Statistic;

const TicketNum = ({ index, poolId }: any) => {
  const { account, chainId, library } = useActiveWeb3React();
  const poolContract = useCobakPoolContract(Pool_Address[chainId || 4]);
  const ticket = useSingleCallResult(poolContract, "getTicket", [
    account,
    poolId,
    index,
  ])?.result?.[0];
  const quantityArr = (ticket?.quantity &&
    Number(ticket?.quantity) &&
    new Array(Number(ticket?.quantity)).fill({})) || [{}];
  return quantityArr.map((_: any, i: number) => (
    <span className="ticket">{Number(ticket?.ticketId) + i || "-"}</span>
  ));
};

const Redeem = ({ data, onFinish }: any) => {
  const [count, setCount] = useState(1);
  const { account, chainId, library } = useActiveWeb3React();
  const poolContract = useCobakPoolContract(Pool_Address[chainId || 4]);
  const addTransaction = useTransactionAdder();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const signData = useQualification(data.projectId, data.status);
  const [approval, approveCallback] = useApproveCallback(
    !data?.priceToken || data?.priceToken?.symbol === "ETH"
      ? CurrencyAmount.ether(JSBI.BigInt(100000000000000000000))
      : new TokenAmount(
          data.priceToken,
          JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffff")
        ),
    Pool_Address[chainId || 4]
  );
  const [ethBalance, setEthBalance] = useState<any>();
  const accountArg = useMemo(() => [account ?? undefined], [account]);
  const cbkContract = useTokenContract(Cobak_Address[chainId || 4]);
  const cbkBalance = useSingleCallResult(cbkContract, "balanceOf", accountArg)
    ?.result?.[0];
  const boughtNum = useSingleCallResult(poolContract, "getBought", [
    data.poolId,
    account,
  ])?.result?.[0];
  const ticketLength = useSingleCallResult(poolContract, "getTicketLength", [
    account,
    data.poolId,
  ])?.result?.[0];
  const ticketsArr =
    ticketLength &&
    Number(ticketLength) &&
    new Array(Number(ticketLength)).fill({});
  const incrementCount = () => {
    if (
      count + 1 <=
      data?.userIdoInfo?.maxTicketCount -
        ((boughtNum && Number(boughtNum)) || 0)
    ) {
      setCount(count + 1);
    }
  };
  const decrementCount = () => {
    if (count >= 2) {
      setCount(count - 1);
    }
  };

  const onChange = (value: number) => {
    let max =
      data?.userIdoInfo?.maxTicketCount -
      ((boughtNum && Number(boughtNum)) || 0);
    if (!value) {
      value = max;
    }
    setCount(value);
  };

  const buyTicket = useCallback(async () => {
    if (approval === ApprovalState.APPROVED) {
      if (poolContract && library) {
        console.log(
          BigNumber.from(parseInt(formatUnits(data?.ticketPrice))),
          "2"
        );

        setLoading(true);
        poolContract
          .buyTicket(
            BigNumber.from(data.poolId),
            BigNumber.from(count),
            signData.signature,
            BigNumber.from(signData?.ticketCount),
            data.priceToken.symbol === "ETH"
              ? {
                  value: BigNumber.from(count).mul(
                    BigNumber.from(data?.ticketPrice)
                  ),
                }
              : {
                  value: BigNumber.from(count).mul(
                    BigNumber.from(parseInt(formatUnits(data?.ticketPrice)))
                  ),
                }
          )
          .then((response: TransactionResponse) => {
            console.log(response);

            addTransaction(response, {
              summary: `Buy Ticket`,
            });
            retry(
              () => {
                return library
                  .getTransactionReceipt(response.hash)
                  .then((receipt) => {
                    if (receipt === null) {
                      console.debug("Retrying for hash", response.hash);
                      throw new RetryableError();
                    }
                    if (receipt) {
                      console.log("trade receipt", receipt);
                      setLoading(false);
                    }
                  });
              },
              {
                n: Infinity,
                minWait: 2500,
                maxWait: 3500,
              }
            );
          })
          .catch((error: any) => {
            setLoading(false);
            const insufficentError =
              "insufficient funds for intrinsic transaction cost";
            const userCancel =
              "MetaMask Tx Signature: User denied transaction signature.";
            const overSold = "execution reverted: 25 times oversold limit";
            const lang = localStorage.getItem("cobak_lang");
            let errorMsg =
              error.reason || error.message || error.error?.message || "";
            if (errorMsg === userCancel) return;

            if (errorMsg === insufficentError && lang === "ko") {
              errorMsg = "잔액부족";
            } else if (error.error?.message === overSold) {
              errorMsg = t("ticket_over_sold");
            }

            message.error({
              content: (
                <>
                  <span>{t("ticket_buy_failed")}</span>
                  <div style={{ maxWidth: 400, overflowWrap: "break-word" }}>
                    {errorMsg}
                  </div>
                </>
              ),
              duration: 5,
            });
          });
      }
    }
  }, [data, approval, count, signData, library]);

  useEffect(() => {
    console.log("running");
    if (library && account) {
      let stale = false;
      onFinish();

      library
        .getBalance(account)
        .then((balance: any) => {
          if (!stale) {
            setEthBalance(balance);
            onFinish();
          }
        })
        .catch(() => {
          if (!stale) {
            setEthBalance(null);
          }
        });

      return () => {
        stale = true;
        setEthBalance(undefined);
      };
    }
  }, [library, account, chainId, loading]);
  // const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK

  return (
    <div className="redeem">
      <div className="tickets">
        <div>
          <div>
            <button onClick={decrementCount}>
              <MinusOutlined />
            </button>
          </div>
          <div>
            <InputNumber
              step={1}
              min={1}
              max={
                data?.userIdoInfo?.maxTicketCount -
                ((boughtNum && Number(boughtNum)) || 0)
              }
              value={count}
              onChange={onChange}
            />
          </div>
          <div>
            <button onClick={incrementCount}>
              <PlusOutlined />
            </button>
          </div>
        </div>
      </div>
      <div className="pay-info">
        <Tag>{t("page_projects_label_pay")}</Tag>
        <div className="ethPay">
          {data?.ticketPrice &&
            numeral(
              formatUnits(
                BigNumber.from(data?.ticketPrice).mul(count),
                data?.priceToken.decimals
              )
            ).format("0,0.00000")}{" "}
          {data?.priceToken?.symbol}
        </div>
        <div className="ethBalance">
          {t("page_projects_label_balance")}:{" "}
          {data?.priceToken?.symbol === "ETH"
            ? ethBalance
              ? `${parseFloat(formatEther(ethBalance)).toPrecision(4)} ETH`
              : "..."
            : cbkBalance
            ? `${Number(formatUnits(BigNumber.from(cbkBalance), 18))} CBK`
            : "..."}
        </div>
      </div>
      {data.status === "open" && signData.signiture !== "undefined" && (
        <Button
          type="primary"
          icon={loading && <LoadingOutlined />}
          disabled={
            loading ||
            data?.userIdoInfo?.maxTicketCount -
              (boughtNum && Number(boughtNum)) ===
              0 ||
            signData.signiture === "undefined"
          }
          onClick={() => {
            approval !== ApprovalState.APPROVED
              ? approveCallback()
              : buyTicket();
          }}
          block
        >
          {approval !== ApprovalState.APPROVED
            ? t("approve")
            : t("page_projects_button_buy_ticket")}
        </Button>
      )}
      {data.status === "soon" && (
        <Countdown value={data.startTime} onFinish={onFinish} />
      )}
      <div className="redeem-info">
        <div>
          <div>{t("page_projects_label_available_tickets")}</div>
          <div>
            {data?.userIdoInfo?.maxTicketCount -
              ((boughtNum && Number(boughtNum)) || 0)}{" "}
            / {data?.userIdoInfo?.maxTicketCount}
          </div>
        </div>
        {boughtNum && Number(boughtNum) > 0 && (
          <div>
            <div className="ticket_label">
              {t("page_projects_label_my_tickets")}
            </div>
            <div>
              {ticketsArr && ticketsArr.length > 0
                ? ticketsArr.map((v: any, i: number) => {
                    return <TicketNum index={i} key={i} poolId={data.poolId} />;
                  })
                : "-"}
            </div>
          </div>
        )}
        <div>
          <div>{t("page_projects_label_paid")}</div>
          <div>
            {boughtNum &&
              numeral(
                formatUnits(
                  boughtNum.mul(BigNumber.from(data?.ticketPrice)),
                  data?.priceToken?.decimals
                )
              ).format("0,0.0000")}{" "}
            {data?.priceToken?.symbol}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Redeem;
