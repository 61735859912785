import { useMemo, useState } from "react";
import { Layout, Button, Collapse, Tag, Statistic, Popover } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useHistory } from "react-router";
import moment from "moment";
import numeral from "numeral";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import { BigNumber } from "@ethersproject/bignumber";
import { formatUnits, parseUnits } from "@ethersproject/units";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useWalletModalToggle } from "state/application/hooks";
import Redeem from "components/Redeem";
import Claim from "components/Claim";
import { useProjectDetail } from "./hooks";
import { useProposalDetail } from "../Governance/hooks";
import { useTiers } from "../Staking/hooks";
import { useCobakPoolContract } from "hooks/useContract";
import { useSingleCallResult } from "state/multicall/hooks";
import { Pool_Address } from "constants/index";
import { ReactComponent as MessageIcon } from "assets/img/message.svg";

import "./index.less";
import { Wrapper } from "./ProjectDetail.styled";
import {
  DiscordIcon,
  MediumIcon,
  TwitterIcon,
  WebsiteIcon,
} from "./ProjectDetail.icon";
import { floatDiv, floatMul } from "utils/calculate";

const { Content } = Layout;
const { Panel } = Collapse;
const { Countdown } = Statistic;

const ProjectDetail = (props: RouteComponentProps<{ projectId: string }>) => {
  const {
    match: {
      params: { projectId },
    },
  } = props;
  const { account, chainId } = useActiveWeb3React();
  const { t } = useTranslation();
  const toggleWalletModal = useWalletModalToggle();
  const [refresh, setRefresh] = useState<number>(1);
  const data = useProjectDetail(projectId, refresh);
  const proposalDetail = useProposalDetail(data?.proposal);
  const tiers = useTiers();
  const history = useHistory();
  const poolContract = useCobakPoolContract(Pool_Address[chainId || 4]);
  const inWhiteList = data?.userIdoInfo?.maxTicketCount > 0;
  const percent =
    !data?.soldTotalPrice || data?.soldTotalPrice === "0"
      ? 0
      : BigNumber.from(data?.soldTotalPrice)
          .mul(100)
          .div(data?.totalPrice)
          .toNumber();
  const poolResult = useSingleCallResult(poolContract, "getPoolResult", [
    data.poolId,
  ])?.result;
  let isNeg;
  // eslint-disable-next-line @typescript-eslint/no-array-constructor
  let winning = new Array();
  if (poolResult) {
    isNeg = poolResult[0];
    const zeroPad = (num: any, places: any) =>
      String(num).padStart(places, "0");
    if (poolResult[1]) {
      poolResult[1].forEach((item: any, index: any) => {
        let size = item.div(Math.pow(2, 31)).toNumber();
        let n = item & 0xffff;
        winning[index] = zeroPad(n, size);
      });
    }
  }

  const onFinish = () => {
    setRefresh(refresh + 1);
  };

  const tge = floatMul(
    parseInt(data?.tokenCountPerTicket),
    floatDiv(parseInt(data?.tgePercentage), 100)
  );

  // 토큰 판매 가격 계산
  const calculateTokenSalePrice = () => {
    // BigNumber 값을 18 소수점 자리로 포맷
    const formattedValue = formatUnits(
      BigNumber.from(data?.ticketPrice),
      data?.priceToken.decimals
    );
    // 결과를 다시 포맷하여 문자열로 변환
    return formattedValue;
  };

  return (
    <Wrapper>
      <div className="bg">
        <img src={data?.background} alt="bg" className="bg-img" />
        <div className="overlay" />
        <div className="intro">
          <img src={data?.tokenInfo?.image} alt="logo" />
          {data ? (
            <div className="intro-text">
              <h3>{data.title}</h3>
              <p>{data.description}</p>
            </div>
          ) : null}
          <div className="social">
            <a href={data.website}>
              <WebsiteIcon />
            </a>
            <a href={data.twitter}>
              <TwitterIcon />
            </a>
            <a href={data.discord}>
              <DiscordIcon />
            </a>
            <a href={data.medium}>
              <MediumIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="main">
        <div className="main-info">
          <div className="main-wrapper">
            <div className="card-list">
              <h3>{t("project_detail.description")}</h3>
              {data && data?.projectId ? (
                <div className="card">
                  <h3>IDO Pool Details</h3>
                  <div className="card-info">
                    <span className="title">
                      {t("project_detail.open_close_time")}
                    </span>
                    <span className="desc">
                      {moment(data.startTime).format("YYYY-MM-DD HH:mm:ss")} ~{" "}
                      <br className="time" />
                      {moment(data.endTime).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                  </div>
                  <div className="card-info">
                    <span className="title">
                      {t("project_detail.winning_tickets")}
                    </span>
                    <span className="desc">{data.ticketCount}</span>
                  </div>
                  <div className="card-info">
                    <span className="title">
                      {t("project_detail.initialClaimAmount")}
                    </span>
                    <span className="desc">
                      {numeral(
                        formatUnits(
                          data?.tokenCountPerTicket,
                          data?.tokenInfo.decimals
                        )
                      ).format("0,0.00")}
                    </span>
                  </div>
                  <div className="card-info">
                    <span className="title">
                      {t("project_detail.tokens_per_ticket")}
                    </span>
                    <span className="desc">
                      {numeral(data?.perTicketsContains).format("0,0.00")}
                    </span>
                  </div>
                  <div className="card-info">
                    <span className="title">
                      {t("project_detail.ticket_sale_price")}
                    </span>
                    <span className="desc">
                      {formatUnits(
                        BigNumber.from(data?.ticketPrice),
                        data?.priceToken.decimals
                      )}{" "}
                      {data?.priceToken?.symbol}
                    </span>
                  </div>
                  <div className="card-info">
                    <span className="title">
                      {t("project_detail.purchase_token")}
                    </span>
                    <span className="desc">{data?.perTokenPriceType}</span>
                  </div>
                  <div className="card-info">
                    <span className="title">{t("project_detail.vesting")}</span>
                    <span className="desc">{data?.configDescription}</span>
                  </div>
                </div>
              ) : null}
              {data && data?.projectId ? (
                <div className="card">
                  <h3>Token Information</h3>
                  <div className="card-info">
                    <span className="title">
                      {t("project_detail.token_name")}
                    </span>
                    <span className="desc">{data?.tokenInfo?.name}</span>
                  </div>
                  <div className="card-info">
                    <span className="title">
                      {t("project_detail.token_symbol")}
                    </span>
                    <span className="desc">{data?.tokenInfo?.symbol}</span>
                  </div>
                  <div className="card-info">
                    <span className="title">
                      {t("project_detail.token_network")}
                    </span>
                    <span className="desc">{data?.tokenNetwork}</span>
                  </div>
                </div>
              ) : null}
              <p>
                <ReactMarkdown>{data?.detailInfo}</ReactMarkdown>
              </p>
            </div>
            <div className="detail">
              <div className="left-content">
                <div
                  style={{
                    background: "#f8fafd",
                  }}
                >
                  {!account && (
                    <Button type="primary" onClick={toggleWalletModal} block>
                      {t("conect_wallet")}
                    </Button>
                  )}
                  {account && data.status === "closed" && (
                    <Tag>{t("page_projects_lable_sold_out")}</Tag>
                  )}
                  {account && inWhiteList && data.status !== "closed" && (
                    <Tag color="#81FFC2">
                      {t("page_projects_available_tips")}
                    </Tag>
                  )}
                  {account &&
                    !inWhiteList &&
                    data.whitelistReady &&
                    data.status !== "closed" && (
                      <Tag color="#FFA3A3">
                        {t("page_projects_unavailable_tips")}
                      </Tag>
                    )}
                  {account && inWhiteList && data.status !== "closed" && (
                    <Redeem data={data} onFinish={onFinish} />
                  )}
                  {!inWhiteList && data.status !== "closed" && (
                    <ul className="no_wthielist">
                      {!data?.userIdoInfo?.voted &&
                        proposalDetail.displayId &&
                        proposalDetail.status !== "passed" &&
                        proposalDetail.status !== "failed" && (
                          <li>
                            {t("page_projects_unavailable_label_voting", {
                              displayId: proposalDetail.displayId,
                            })}
                            <Button
                              type="primary"
                              block
                              onClick={() => {
                                history.push(`/proposal/${data.proposal}`);
                              }}
                            >
                              {t("page_projects_button_voting")}
                            </Button>
                          </li>
                        )}
                      {data?.userIdoInfo?.stakingCount < 5000 &&
                        data.status === "soon" &&
                        !data.whitelistReady && (
                          <li>
                            {t("page_projects_unavailable_label_stake", {
                              amount: numeral(
                                tiers[tiers.length - 1]?.count
                              ).format("0,0"),
                            })}
                            <br />
                            {t("page_projects_note")}
                            <Button
                              type="primary"
                              block
                              onClick={() => {
                                history.push(`/staking`);
                              }}
                            >
                              {t("page_projects_button_staking")}
                            </Button>
                          </li>
                        )}
                    </ul>
                  )}
                  <div className="info">
                    {(data.status === "open" ||
                      data.status === "soon" ||
                      data.status === "closed") && (
                      <>
                        <div>
                          <div>
                            {t("page_projects_label_amount_pre_ticket")}
                          </div>
                          <div>
                            {numeral(
                              formatUnits(
                                BigNumber.from(data?.tokenCountPerTicket),
                                data.tokenInfo.decimals
                              )
                            ).format("0,0.00")}{" "}
                            {data?.tokenInfo?.symbol}
                          </div>
                        </div>
                        <div>
                          <div>{t("page_projects_label_total_tickets")}</div>
                          <div>{data.ticketCount}</div>
                        </div>
                        <div>
                          <div>{t("page_projects_label_sold_tickets")}</div>
                          <div>{data.soldTicketCount}</div>
                        </div>
                        <div>
                          <div>{t("page_projects_label_total_committed")}</div>
                          <div>
                            {data.soldTotalPrice
                              ? numeral(
                                  formatUnits(
                                    BigNumber.from(data.soldTotalPrice),
                                    data.priceToken.decimals
                                  )
                                ).format("0,0.0000")
                              : "--"}{" "}
                            {data?.priceToken?.symbol} ({percent}%)
                          </div>
                        </div>
                      </>
                    )}
                    {data.status === "soon" && (
                      <div>
                        <div>{t("page_projects_label_start_time")}</div>
                        <div>
                          {moment(data.startTime).format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                      </div>
                    )}
                    {data.status === "open" && (
                      <div>
                        <div>{t("page_projects_label_close_in")}</div>
                        <div>
                          <Countdown
                            value={data.endTime}
                            onFinish={onFinish}
                            format="D[d] H[h] m[m] s[s]"
                          />
                        </div>
                      </div>
                    )}
                    {data.status === "closed" && (
                      <div>
                        <div>{t("page_projects_label_closed_at")}</div>
                        <div>
                          {moment(data.endTime).format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                      </div>
                    )}
                    {data.status === "closed" &&
                      account &&
                      poolResult &&
                      poolResult[1].length > 0 && (
                        <div>
                          {!isNeg && (
                            <div className="ticket_label">
                              {t("page_projects_label_lucky_numbers")}
                              <Popover
                                content={t(
                                  "page_projects_label_lucky_numbers_tips_1"
                                )}
                              >
                                <MessageIcon />
                              </Popover>
                            </div>
                          )}
                          {isNeg && (
                            <div className="ticket_label">
                              {t("page_projects_label_exclude_numbers")}
                              <Popover
                                content={t(
                                  "page_projects_label_lucky_numbers_tips_2"
                                )}
                              >
                                <MessageIcon />
                              </Popover>
                            </div>
                          )}
                          <div>
                            {winning && winning.length > 0
                              ? winning.map((v: any) => {
                                  return (
                                    <>
                                      <span className="ticket">{v}</span>
                                    </>
                                  );
                                })
                              : "-"}
                          </div>
                        </div>
                      )}
                  </div>
                  {account && data.status === "closed" && (
                    <Claim data={data} setRefresh={onFinish} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProjectDetail;
