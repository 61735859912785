import { MaxUint256 } from '@ethersproject/constants';
import { TransactionResponse } from '@ethersproject/providers';
import { Trade, TokenAmount, CurrencyAmount, ETHER, JSBI } from '@uniswap/sdk';
import { useCallback, useMemo, useState } from 'react';
import { useTokenAllowance } from '../data/Allowances';
import {
  useTransactionAdder,
  useHasPendingApproval,
} from '../state/transactions/hooks';
import { calculateGasMargin } from '../utils';
import { useTokenContract } from './useContract';
import useActiveWeb3React from './useActiveWeb3React';

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount,
  spender?: string
): [ApprovalState, (amount?: number) => Promise<void>, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { account } = useActiveWeb3React();
  const token =
    amountToApprove instanceof TokenAmount ? amountToApprove.token : undefined;
  const currentAllowance = useTokenAllowance(
    token,
    account ?? undefined,
    spender
  );
  const pendingApproval = useHasPendingApproval(token?.address, spender);

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
    if (amountToApprove.currency === ETHER) return ApprovalState.APPROVED;
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.NOT_APPROVED;
    // amountToApprove will be defined if currentAllowance is

    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [amountToApprove, currentAllowance, pendingApproval, spender]);

  const tokenContract = useTokenContract(token?.address);
  const addTransaction = useTransactionAdder();

  const approve = useCallback(
    async (amount?: number): Promise<void> => {
      setIsLoading(true);
      if (approvalState !== ApprovalState.NOT_APPROVED) {
        console.error('approve was called unnecessarily');
        setIsLoading(false);
        return;
      }
      if (!token) {
        console.error('no token');
        setIsLoading(false);
        return;
      }

      if (!tokenContract) {
        console.error('tokenContract is null');
        setIsLoading(false);
        return;
      }

      if (!amountToApprove) {
        console.error('missing amount to approve');
        setIsLoading(false);
        return;
      }

      if (!spender) {
        console.error('no spender');
        setIsLoading(false);
        return;
      }

      const approveAmount = amount
        ? (BigInt(amount.toString()) * BigInt('1000000000000000000')).toString()
        : amountToApprove.raw.toString();

      let useExact = amount ? true : false;
      const estimatedGas = await tokenContract.estimateGas
        .approve(spender, useExact ? approveAmount : MaxUint256)
        .catch(() => {
          // general fallback for tokens who restrict approval amounts
          useExact = true;
          return tokenContract.estimateGas.approve(spender, approveAmount);
        });

      return tokenContract
        .approve(spender, useExact ? approveAmount : MaxUint256, {
          gasLimit: calculateGasMargin(estimatedGas),
        })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: 'Approve ' + amountToApprove.currency.symbol,
            approval: { tokenAddress: token.address, spender: spender },
          });
          setIsLoading(false);
        })
        .catch((error: Error) => {
          console.debug('Failed to approve token', error);
          setIsLoading(false);
          throw error;
        });
    },
    [
      approvalState,
      token,
      tokenContract,
      amountToApprove,
      spender,
      addTransaction,
    ]
  );

  return [approvalState, approve, isLoading];
}
